import { Container, Typography, Box } from '@mui/material';
import React from 'react';
import './Goals.css';

export class AboutUsComponent extends React.Component {
    render() {
        return (
            <Container sx={{ my: 3, p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Typography variant="h1" color={'black'} textAlign="center">
                    Über uns!
                </Typography>

                <Container>
                    <Box sx={{ marginTop: 3 }}>
                        <Typography variant="body" color={'black'} textAlign="center">
                            Der Verein „Bewegtes Lernen – Gesundheitsförderung in Volksschulen/Wien“
                            wurde am 13. August gegründet. Unser Ziel war es, die
                            Gesundheitsförderung von Schüler*innen im Sinne der WHO (World Health
                            Organisation) zu fördern. Das heißt Förderung und Erhaltung der
                            körperlichen, geistigen, seelischen und sozialen Gesundheit im
                            ganzheitlichen Sinn durch und mit Bewegung.
                            <br />
                            Wir arbeiten eng mit dem Stadtschulrat und der Pädagogischen Hochschule
                            Wien zusammen. Unser Team versteht sich als Ansprechpartner*in für alle
                            Wiener Pädagog*innen und Direktor*innen in Wien.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: 8 }}>
                        <Typography
                            sx={{ marginBottom: 2 }}
                            variant="h2"
                            color={'black'}
                            textAlign="left"
                        >
                            Unsere Leistungen und Ziele:
                        </Typography>
                        <ul sx={{ listStyleType: 'disc', m: 1250, p: 0 }}>
                            <li style={{ marginTop: '0px' }}>
                                Unterstützung bei der Einrichtung von Schwerpunktklassen an Schulen
                                zum Thema „Bewegtes Lernen – Gesundheitsförderung“
                            </li>
                            <li>
                                Organisation und Durchführung des Diplomlehrganges Bewegtes Lernen –
                                Gesundheitsförderung
                            </li>
                            <li>
                                Information und Aufklärung von Schüler*innen, Pädagog*innen und
                                Eltern über die seelischen, geistigen, körperlichen und sozialen
                                Faktoren, die essenziell für unsere Gesundheit sind
                            </li>
                            <li>
                                Entwicklung von Konzepten für die Aus- und Fortbildung rund um das
                                Thema „Bewegtes Lernen – Gesundheitsförderung“
                            </li>
                            <li>Vermittlung von Expert*innen an Schulen für Projektklassen</li>
                            <li>
                                Abhaltung von Veranstaltungen, wie
                                <ul>
                                    <li>Gesundheitstagen</li>
                                    <li>Elternabenden</li>
                                    <li>Workshops</li>
                                </ul>
                            </li>
                            <li>Gerätepool für Projektklassen</li>
                            <li>
                                die Verbesserung der Lernvoraussetzungen und Lernbedingungen von
                                Schüler/innen;
                            </li>
                            <li>
                                wissenschaftliche Studien zur Implementierung des Bewegten Lernens
                                und der Gesundheitsförderung in das Schulentwicklungsprogramm
                            </li>
                        </ul>
                    </Box>
                </Container>
            </Container>
        );
    }
}
