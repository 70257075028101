import { AppBar, Button, IconButton, SwipeableDrawer, Toolbar, List } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import { get } from '../global';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircleOutlined } from '@mui/icons-material';

export class AppBarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appBar: null,
            userIcon: null,
            alphaValue: 100,
            drawerOpen: false,
            collapsed: false,
            firstname: '',
            lastname: '',
            email: '',
            phoneNumber: '',
            role: '',
            aboutUs: null,
        };
        this.openAppBar = this.openAppBar.bind(this);
        this.closeAppBar = this.closeAppBar.bind(this);
        this.openUserIcon = this.openUserIcon.bind(this);
        this.closeUserIcon = this.closeUserIcon.bind(this);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.register = this.register.bind(this);
        this.checkIOS = this.checkIOS.bind(this);
        this.impressum = this.impressum.bind(this);
        this.documents = this.documents.bind(this);
    }

    componentDidMount() {
        get('api/v1/user/me', (data, status) => {
            if (status === 200) {
                this.setState({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    role: data.role,
                });
            }
        });
    }

    toggleDrawerOpen = () => {
        let state = !this.state.drawerOpen;
        this.setState({ drawerOpen: state });
    };

    render() {
        return (
            <AppBar position="sticky" id="NavBar" sx={{ width: '100%', height: '10vh' }}>
                <Toolbar>
                    {!this.props.isMobile ? (
                        <Box sx={{ flexGrow: 1, height: '10vh', alignSelf: 'flex-start' }}>
                            <Stack
                                direction="row"
                                alignSelf="center"
                                height="10vh"
                                justifyContent="left"
                                alignItems="left"
                                spacing={2}
                            >
                                <Link
                                    to={'/news'}
                                    style={{ textDecoration: 'none', alignSelf: 'center' }}
                                    onClick={this.closeAppBar}
                                >
                                    <Button
                                        variant="link"
                                        sx={{ my: 2, display: 'block', fontSize: 13 }}
                                    >
                                        News
                                    </Button>
                                </Link>
                                <Link
                                    to={'/ausbildung'}
                                    style={{ textDecoration: 'none', alignSelf: 'center' }}
                                    onClick={this.closeAppBar}
                                >
                                    <Button
                                        variant="link"
                                        sx={{ my: 2, display: 'block', fontSize: 13 }}
                                    >
                                        Ausbildung
                                    </Button>
                                </Link>
                                <Button
                                    variant="link"
                                    sx={{ my: 2, display: 'block', fontSize: 13 }}
                                    onClick={e => this.setState({ aboutUs: e.currentTarget })}
                                >
                                    Über uns
                                </Button>
                                <Menu
                                    id="about-us-menu"
                                    anchorEl={this.state.aboutUs}
                                    open={Boolean(this.state.aboutUs)}
                                    onClose={() => this.setState({ aboutUs: null })}
                                    MenuListProps={{
                                        'aria-labelledby': 'about-us-button',
                                    }}
                                >
                                    <Link
                                        to="/ueber-uns"
                                        style={{ textDecoration: 'none', color: 'black' }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                this.setState({ aboutUs: null });
                                            }}
                                        >
                                            Über Uns
                                        </MenuItem>
                                    </Link>
                                    <Link
                                        to="/ziele"
                                        style={{ textDecoration: 'none', color: 'black' }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                this.setState({ aboutUs: null });
                                            }}
                                        >
                                            Ziele
                                        </MenuItem>
                                    </Link>
                                    <Link
                                        to="/team"
                                        style={{ textDecoration: 'none', color: 'black' }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                this.setState({ aboutUs: null });
                                            }}
                                        >
                                            Team
                                        </MenuItem>
                                    </Link>
                                    <Link
                                        to="/kooperationen"
                                        style={{ textDecoration: 'none', color: 'black' }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                this.setState({ aboutUs: null });
                                            }}
                                        >
                                            Kooperationen
                                        </MenuItem>
                                    </Link>
                                    <Link
                                        to="/gründer"
                                        style={{ textDecoration: 'none', color: 'black' }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                this.setState({ aboutUs: null });
                                            }}
                                        >
                                            Die Begründer
                                        </MenuItem>
                                    </Link>
                                </Menu>
                                <Link
                                    to={'/privacy'}
                                    style={{ textDecoration: 'none', alignSelf: 'center' }}
                                    onClick={this.closeAppBar}
                                >
                                    <Button
                                        variant="link"
                                        sx={{ my: 2, display: 'block', fontSize: 13 }}
                                    >
                                        Datenschutz
                                    </Button>
                                </Link>
                            </Stack>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                flexGrow: 1,
                                height: '10vh',
                                alignItems: 'center',
                                alignSelf: 'flex-start',
                            }}
                        >
                            <Button
                                sx={{ height: '7vh', my: '1.5vh' }}
                                onClick={this.toggleDrawerOpen}
                            >
                                <MenuIcon sx={{ height: '6vh', width: '6vh', color: 'white' }} />
                            </Button>
                            <SwipeableDrawer
                                anchor="top"
                                open={this.state.drawerOpen}
                                onClose={this.toggleDrawerOpen}
                            >
                                <List sx={{}}></List>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    backgroundColor="#E4630D"
                                >
                                    <Link
                                        to={'/news'}
                                        style={{
                                            textDecoration: 'none',
                                            alignSelf: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        onClick={this.toggleDrawerOpen}
                                    >
                                        <Button
                                            variant="link"
                                            sx={{
                                                py: 2,
                                                width: '100%',
                                                height: '100%',
                                                display: 'block',
                                                fontSize: 13,
                                            }}
                                        >
                                            News
                                        </Button>
                                    </Link>
                                    <Link
                                        to={'/ausbildung'}
                                        style={{
                                            textDecoration: 'none',
                                            alignSelf: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        onClick={this.toggleDrawerOpen}
                                    >
                                        <Button
                                            variant="link"
                                            sx={{
                                                py: 2,
                                                width: '100%',
                                                height: '100%',
                                                display: 'block',
                                                fontSize: 13,
                                            }}
                                        >
                                            Ausbildung
                                        </Button>
                                    </Link>
                                    <Button
                                        variant="link"
                                        onClick={e => this.setState({ aboutUs: e.currentTarget })}
                                        sx={{
                                            py: 2,
                                            width: '100%',
                                            height: '100%',
                                            display: 'block',
                                            fontSize: 13,
                                        }}
                                    >
                                        Über uns
                                    </Button>
                                    <Menu
                                        id="about-us-menu"
                                        anchorEl={this.state.aboutUs}
                                        open={Boolean(this.state.aboutUs)}
                                        onClose={() => this.setState({ aboutUs: null })}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                        MenuListProps={{
                                            'aria-labelledby': 'about-us-button',
                                        }}
                                    >
                                        <Link
                                            to="/ueber-uns"
                                            style={{ textDecoration: 'none', color: 'black' }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    this.setState({ aboutUs: null });
                                                    this.toggleDrawerOpen();
                                                }}
                                            >
                                                Über Uns
                                            </MenuItem>
                                        </Link>
                                        <Link
                                            to="/ziele"
                                            style={{ textDecoration: 'none', color: 'black' }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    this.setState({ aboutUs: null });
                                                    this.toggleDrawerOpen();
                                                }}
                                            >
                                                Ziele
                                            </MenuItem>
                                        </Link>
                                        <Link
                                            to="/team"
                                            style={{ textDecoration: 'none', color: 'black' }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    this.setState({ aboutUs: null });
                                                    this.toggleDrawerOpen();
                                                }}
                                            >
                                                Team
                                            </MenuItem>
                                        </Link>
                                        <Link
                                            to="/kooperationen"
                                            style={{ textDecoration: 'none', color: 'black' }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    this.setState({ aboutUs: null });
                                                    this.toggleDrawerOpen();
                                                }}
                                            >
                                                Kooperationen
                                            </MenuItem>
                                        </Link>
                                        <Link
                                            to="/gründer"
                                            style={{ textDecoration: 'none', color: 'black' }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    this.setState({ aboutUs: null });
                                                    this.toggleDrawerOpen();
                                                }}
                                            >
                                                Die Begründer
                                            </MenuItem>
                                        </Link>
                                    </Menu>
                                    <Link
                                        to={'/privacy'}
                                        style={{
                                            textDecoration: 'none',
                                            alignSelf: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        onClick={this.toggleDrawerOpen}
                                    >
                                        <Button
                                            variant="link"
                                            sx={{
                                                py: 2,
                                                width: '100%',
                                                height: '100%',
                                                display: 'block',
                                                fontSize: 13,
                                            }}
                                        >
                                            Datenschutz
                                        </Button>
                                    </Link>
                                    {this.props.loggedIn &&
                                        (this.props.role === 'TEACHER' ? (
                                            <Link
                                                to={'/projekte'}
                                                style={{
                                                    textDecoration: 'none',
                                                    alignSelf: 'center',
                                                }}
                                                onClick={this.toggleDrawerOpen}
                                            >
                                                <Button
                                                    variant="link"
                                                    sx={{
                                                        py: 2,
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'block',
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    Projekte
                                                </Button>
                                            </Link>
                                        ) : this.props.role === 'REFEREE' ? (
                                            <Link
                                                to={'/buchungen'}
                                                style={{
                                                    textDecoration: 'none',
                                                    alignSelf: 'center',
                                                }}
                                                onClick={this.toggleDrawerOpen}
                                            >
                                                <Button
                                                    variant="link"
                                                    sx={{
                                                        py: 2,
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'block',
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    Buchungen
                                                </Button>
                                            </Link>
                                        ) : (
                                            <></>
                                        ))}
                                    {this.props.loggedIn ? (
                                        <Button
                                            variant="link"
                                            sx={{
                                                py: 2,
                                                width: '100%',
                                                height: '100%',
                                                display: 'block',
                                                fontSize: 13,
                                            }}
                                            onClick={() => {
                                                this.logout();
                                                this.toggleDrawerOpen();
                                            }}
                                        >
                                            Abmelden
                                        </Button>
                                    ) : (
                                        <Link
                                            to={'/login'}
                                            style={{ textDecoration: 'none', alignSelf: 'center' }}
                                            onClick={this.toggleDrawerOpen}
                                        >
                                            <Button
                                                variant="normal"
                                                sx={{
                                                    py: 2,
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'block',
                                                    fontSize: 13,
                                                }}
                                            >
                                                Anmelden
                                            </Button>
                                        </Link>
                                    )}
                                </Stack>
                            </SwipeableDrawer>
                        </Box>
                    )}
                    {!this.state.collapsed ? (
                        <Box
                            sx={{ flexGrow: 0 }}
                            style={{ mr: 3, height: '10vh', alignSelf: 'flex-start' }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                height="10vh"
                                alignItems="center"
                                spacing={2}
                            >
                                {this.props.loggedIn &&
                                    (this.props.role === 'TEACHER' ? (
                                        <Link
                                            to={'/projekte'}
                                            style={{
                                                textDecoration: 'none',
                                                alignSelf: 'center',
                                            }}
                                            onClick={this.closeAppBar}
                                        >
                                            <Button
                                                variant="link"
                                                sx={{ my: 2, display: 'block', fontSize: 13 }}
                                            >
                                                Projekte
                                            </Button>
                                        </Link>
                                    ) : this.props.role === 'REFEREE' ? (
                                        <Link
                                            to={'/buchungen'}
                                            style={{
                                                textDecoration: 'none',
                                                alignSelf: 'center',
                                            }}
                                            onClick={this.closeAppBar}
                                        >
                                            <Button
                                                variant="link"
                                                sx={{ my: 2, display: 'block', fontSize: 13 }}
                                            >
                                                Buchungen
                                            </Button>
                                        </Link>
                                    ) : (
                                        <></>
                                    ))}
                                {this.props.loggedIn && (
                                    <Link
                                        to={'/profile'}
                                        style={{
                                            textDecoration: 'none',
                                            alignSelf: 'center',
                                            color: 'white',
                                        }}
                                        onClick={this.closeAppBar}
                                    >
                                        <IconButton
                                            color="inherit"
                                            sx={{ my: 2, display: 'block', fontSize: 13 }}
                                        >
                                            <AccountCircleOutlined />
                                        </IconButton>
                                    </Link>
                                )}
                                {this.props.loggedIn ? (
                                    <>
                                        <Button
                                            variant="normal"
                                            sx={{
                                                my: 2,
                                                display: 'block',
                                                fontSize: 13,
                                                alignSelf: 'center',
                                            }}
                                            onClick={this.logout}
                                        >
                                            Abmelden
                                        </Button>
                                    </>
                                ) : (
                                    <Link
                                        to={'/login'}
                                        style={{ textDecoration: 'none', alignSelf: 'center' }}
                                        onClick={this.closeAppBar}
                                    >
                                        <Button
                                            variant="normal"
                                            sx={{ my: 2, display: 'block', fontSize: 13 }}
                                        >
                                            Anmelden
                                        </Button>
                                    </Link>
                                )}
                            </Stack>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {
                        //FIXME: Kleiner Rand Oben und unten - von !(sehr cooler) Pascal
                    }
                    <Box
                        sx={{
                            flexGrow: 0,
                            ml: '5%',
                            mr: '1.5%',
                            my: '0.25vh',
                            alignSelf: 'flex-start',
                            height: '9.5vh',
                        }}
                    >
                        <Link to={'/'} style={{ textDecoration: 'none' }}>
                            <img src="img/logo.png" alt="BWL Logo" height={'100%'} />
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }
    openAppBar(e) {
        this.setState({ appBar: e.currentTarget });
    }
    closeAppBar() {
        this.setState({ appBar: null });
    }
    openUserIcon(e) {
        this.setState({ userIcon: e.currentTarget });
    }
    closeUserIcon() {
        this.setState({ userIcon: null });
    }
    logout() {
        this.setState({ userIcon: null });
        get('logout', (data, status) => {
            if (status === 200) {
                this.props.navigate('/login');
                this.props.changeBar('Logged out!', 'success');
                this.props.setPerm(false, false, '');
            }
        });
    }
    login() {
        this.props.navigate('/login');
    }
    register() {
        this.props.navigate('/register');
    }
    impressum() {
        this.props.navigate('/impressum');
    }
    documents() {
        this.props.navigate('/documents');
    }
    checkIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
}
