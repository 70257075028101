import { Paper, Typography, Box, Button, Grid } from "@mui/material";
import React from "react";

export class TeamMemberComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    //<TeamMemberComponent isLead="true" role="Kassierin / Verechnung" img="img/Team/Kathrin.png" alt="Foto - Nina-Katrin Wojner" name="Nina-Katrin Wojner" title="MA." href="mailto:nina.katrin@gmail.com" isMobile={this.props.isMobile}/>
    render() {
        return (
            <>
                {!this.props.isMobile ? (
                    <>
                        {!this.props.isLead ? (
                            <Grid item xs={3.5}>
                                <Typography sx={{ marginBottom: 1 }} variant="h3" color={'black'} textAlign="center">
                                    {this.props.role}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '70%' }}>
                                    <img src={this.props.img} alt={this.props.alt} style={{ width: '70%', marginBottom: '-4px' }} />
                                </Box>
                                <Typography sx={{ marginTop: 2 }} variant="h4" color={'black'} textAlign="center">
                                    {this.props.name}
                                </Typography>
                                <Typography sx={{ marginTop: -1 }} variant="h5" color={'black'} textAlign="center">
                                    {this.props.title}
                                </Typography>
                                <Box sx={{ marginTop: 3, textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <Button variant="contained" sx={{ backgroundColor: '#E4630D', '&:hover': { backgroundColor: '#c1540b' } }} href={this.props.href}>
                                        Kontakt
                                    </Button>
                                </Box>
                            </Grid>
                        ) : (
                            <Grid item xs={5}>
                                <Typography sx={{ marginBottom: 1 }} variant="h2" color={'black'} textAlign="center">
                                    {this.props.role}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '70%' }}>
                                    <img src={this.props.img} alt={this.props.alt} style={{ width: '70%', marginBottom: '-7px' }} />
                                </Box>
                                <Typography sx={{ marginTop: 2 }} variant="h3" color={'black'} textAlign="center">
                                    {this.props.name}
                                </Typography>
                                <Typography sx={{ marginTop: -1 }} variant="h4" color={'black'} textAlign="center">
                                    {this.props.title}
                                </Typography>

                                <Box sx={{ marginTop: 3, textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <Button variant="contained" sx={{ backgroundColor: '#E4630D', '&:hover': { backgroundColor: '#c1540b' } }} href={this.props.href}>
                                        Kontakt
                                    </Button>
                                    <Button variant="outlined" sx={{ color: '#E4630D', borderColor: '#E4630D', '&:hover': { backgroundColor: '#fef0e7', borderColor: '#c1540b' } }} href="https://learn4life.co.at">
                                        mehr Erfahren
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </>
                ) : (
                    <Box sx={{my: '1%'}}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                            <Typography variant="h3" color={'black'} textAlign="center">
                                {this.props.role}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '70%' }}>
                                <img src={this.props.img} alt={this.props.alt} style={{ width: '70%', marginBottom: '-7px' }} />
                            </Box>
                            <Typography variant="h4" color={'black'} textAlign="center">
                                {this.props.name}
                            </Typography>
                            <Typography variant="h5" color={'black'} textAlign="center">
                                {this.props.title}
                            </Typography>
                            <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 2 }}>
                                <Button variant="contained" sx={{ backgroundColor: '#E4630D', '&:hover': { backgroundColor: '#c1540b' } }} href={this.props.href}>
                                    Kontakt
                                </Button>
                                {this.props.isLead && (
                                    <Button variant="outlined" sx={{ color: '#E4630D', borderColor: '#E4630D', '&:hover': { backgroundColor: '#fef0e7', borderColor: '#c1540b' } }} href="https://learn4life.co.at">
                                        mehr Erfahren
                                    </Button>
                                )}
                            </Box>
                        </Paper>
                    </Box>
                )}
            </>
        )
    }
}
