import { Component } from "react";
import BasicCard from "./BasicCard";
import { Grid } from "@mui/material";

class CardSectionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.design =
        {
            stud: { width: '2.5%' },
            card: { height: '100%' },
        };
    }

    render() {
        return (
            <Grid container spacing={1} id="Card-Section" sx={{ backgroundColor: '#E4630D', padding: '2.5%', height: '95vh', marginTop: 0 }}>
                <Grid xs={4} item><BasicCard srcImage='img/LandingPage/cardSection_img1.jpg' alt='teacher' Header='Lerne sportlich zu Unterrichten' URI='/infos-lehrpersonen' /></Grid>
                <Grid xs={4} item><BasicCard srcImage='img/LandingPage/cardSection_img2.png' alt='student' Header='Erfahre mehr über uns' URI=''/></Grid>
                <Grid xs={4} item><BasicCard srcImage='img/LandingPage/cardSection_img3.jpg' alt='company' Header='Werde Referent*in' URI='/werde-referent'  /></Grid>
            </Grid>
        );
    }
}

export default CardSectionComponent;