import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

export class DatenschutzComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cellStyle: {
                border: '1px solid lightgrey',
                borderLeft: '2px solid lightgrey',
                borderRight: '2px solid lightgrey',
                color: 'black',
            },
        };
    }

    scrollToTargetAdjusted(id) {
        var element = document.getElementById(id);
        var headerOffset = 100;
        var elementPosition = element.offsetTop;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }

    render() {
        return (
            <Box>
                <Stack
                    sx={{ mt: 3 }}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <h1>Datenschutzerklärung</h1>
                </Stack>
                <Stack
                    spacing={2}
                    direction="column"
                    alignItems={'flex-start'}
                    sx={{ px: 6 }}
                    square
                >
                    <div>
                        <h2>Inhalt</h2>
                        <div>
                            <ol>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Über-unsere-Datenschutzerklärung',
                                                )
                                            }
                                        >
                                            Über unsere Datenschutzerklärung
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted('Definitionen')
                                            }
                                        >
                                            Definitionen
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Wie-kann-ich-euch-erreichen',
                                                )
                                            }
                                        >
                                            Wie kann ich euch erreichen?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Welche-Arten-von-personenbezogenen-Daten',
                                                )
                                            }
                                        >
                                            Welche Arten von personenbezogenen Daten verarbeitet ihr
                                            von mir?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Warum-ist-die-Angabe-einiger-meiner-personenbezogenen-Daten-erforderlich',
                                                )
                                            }
                                        >
                                            Warum ist die Angabe einiger meiner personenbezogenen
                                            Daten erforderlich?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Warum-und-wofür-nutzt-ihr-meine-personenbezogenen-Daten',
                                                )
                                            }
                                        >
                                            Warum und wofür nutzt ihr meine personenbezogenen Daten?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Wie-lange-speichert-ihr-meine-personenbezogenen-Daten',
                                                )
                                            }
                                        >
                                            Wie lange speichert ihr meine personenbezogenen Daten?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Mit-wem-teilt-ihr-meine-personenbezogenen-Daten',
                                                )
                                            }
                                        >
                                            Mit wem teilt ihr meine personenbezogenen Daten?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Transferiert-ihr-meine-personenbezogenen-Daten-an-Orte-außerhalb-der-Europäischen-Union',
                                                )
                                            }
                                        >
                                            Transferiert ihr meine personenbezogenen Daten an Orte
                                            außerhalb der Europäischen Union?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted('Wie-nutzt-ihr-Cookies')
                                            }
                                        >
                                            Wie nutzt ihr Cookies?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted('Was-sind-meine-Rechte')
                                            }
                                        >
                                            Was sind meine Rechte?
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Datenschutzbeauftragter',
                                                )
                                            }
                                        >
                                            Datenschutzbeauftragter
                                        </a>
                                    </u>
                                </li>
                                <li>
                                    <u>
                                        <a
                                            href="?"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.scrollToTargetAdjusted(
                                                    'Änderungen-an-dieser-Datenschutzerklärung',
                                                )
                                            }
                                        >
                                            Änderungen an dieser Datenschutzerklärung
                                        </a>
                                    </u>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <br />
                    <h2 id="Über-unsere-Datenschutzerklärung">
                        1. Über unsere Datenschutzerklärung
                    </h2>
                    <p>
                        Diese Datenschutzerklärung gilt für die personenbezogenen Daten, die BWL von
                        Dir oder über Dich sammelt, wenn Du unsere Plattform besuchst oder unsere
                        Dienste nutzt. Im Folgenden erläutern wir, warum und wie wir Deine
                        persönlichen Daten verarbeiten. Verantwortlich für die Datenverarbeitung im
                        Rahmen dieser Plattform im Sinne der EU-DatenschutzGrundverordnung
                        (EU-DSGVO):
                    </p>
                    <p>
                        Bewegtes Lernen – Gesundheitsförderung in Volksschulen / Wien
                        <br /> Claudia Slavik, MA
                        <br />
                        Dipl. Päd. Marina Thuma, MA MBA
                        <br />
                        Bendagasse 1-2, 1230 Wien
                        <br />
                        E-Mail.:{' '}
                        <a href="mailto:office@bewegtes-lernen-wien.at">
                            office@bewegtes-lernen-wien.at
                        </a>
                        &nbsp;
                    </p>
                    <p>Diese Datenschutzerklärung gilt ab den 05.01.2024.</p>
                    <p>
                        Wir können diese Datenschutzerklärung jederzeit ändern und werden Dich
                        innerhalb eines angemessenen Zeitraums vor dem Inkrafttreten einer späteren
                        Version per E-Mail darüber informieren.
                    </p>
                    <br />
                    <h2 id="Definitionen">2. Definitionen</h2>
                    <p>
                        <b>“Services”</b> sind alle Online-Dienste, die wir Dir anbieten, wenn Du
                        auf unsere Plattform zugreifst oder diese nutzt.
                    </p>
                    <p>
                        <b>“Plattform”</b> bezeichnet unsere Website, auf die Du unter folgender
                        Adresse zugreifen kannst:{' '}
                        <a href="//www.bewegtes-lernen-wien.at">www.bewegtes-lernen-wien.at</a>.
                    </p>
                    <p>
                        <b>“Wir”</b>, <b>“Unser”</b>, <b>„BWL“</b> und <b>“Bewegtes Lernen Wien”</b>{' '}
                        bedeuten unser Unternehmen Bewegtes Lernen – Gesundheitsförderung in
                        Volksschulen / Wien.
                    </p>
                    <p>
                        <b>Personenbezogene Daten</b> sind alle Informationen, die sich auf Dich
                        beziehen und anhand derer Du direkt oder indirekt identifiziert werden
                        kannst
                    </p>
                    <br />
                    <h2 id="Wie-kann-ich-euch-erreichen">3. Wie kann ich euch erreichen?</h2>
                    <p>Du kannst uns auf verschiedenen Wegen kontaktieren:</p>
                    <ul>
                        <li>
                            Post: Bewegtes Lernen – Gesundheitsförderung in Volksschulen / Wien,
                            Bendagasse 1-2, 1230 Wien, Österreich
                        </li>
                        <li id="Datenschutzbeauftragter">
                            {' '}
                            Wir haben ebenfalls einen Datenschutzbeauftragten ernannt, den Du über
                            folgende E-Mail erreichen kannst:{' '}
                            <a href="mailto:office@bewegtes-lernen-wien.at">
                                office@bewegtes-lernen-wien.at
                            </a>
                        </li>
                    </ul>
                    <br />
                    <h2 id="Welche-Arten-von-personenbezogenen-Daten">
                        4. Welche Arten von personenbezogenen Daten verarbeitet ihr von mir?
                    </h2>
                    <p></p>
                    <p>Wir verarbeiten verschiedene Arten von personenbezogenen Daten:</p>
                    <Box sx={{ overflow: 'auto' }}>
                        <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                            <Table
                                style={{
                                    borderCollapse: 'collapse',
                                    border: '2px solid lightgrey',
                                }}
                            >
                                <TableBody>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>Personenbezogene Daten, die Du angeben musst:</b>{' '}
                                            Abhängig davon, wie Du unsere Services nutzt, musst Du
                                            uns bestimmte Arten von personenbezogenen Daten
                                            bereitstellen.
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>Account-Informationen</b> (E-Mail-Adresse, Vorname,
                                            Nachname, Passwort)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>
                                                Personenbezogene Daten, die Du unter Umständen
                                                angibst:
                                            </b>{' '}
                                            Wenn Du unsere Services nutzt, gibst Du unter Umstänn
                                            zusätzliche Arten von personenbezogenen Daten an.
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>Zusätzliche Informationen</b> (Telefonnummer)
                                            <br />
                                            <b>Deine Aktivität</b> (Deine Buchungen, Deine
                                            Bestellungen, Deine Projekte)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>
                                                Personenbezogene Daten, die wir automatisiert
                                                sammeln:
                                            </b>{' '}
                                            Wir werden ebenfalls bestimmte Arten von
                                            personenbezogeneDaten sammeln, wenn zt.
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>Verhaltensdaten</b> (Art des Kontakts, ob du als
                                            gebuchter Referent per Email oder Telefonnummer ee
                                            Lehrkraft kontaktierst)
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    <br />
                    <h2 id="Warum-ist-die-Angabe-einiger-meiner-personenbezogenen-Daten-erforderlich">
                        5. Warum ist die Angabe einiger meiner personenbezogenen Daten erforderlich?
                    </h2>
                    <p>
                        Manchmal verlangen wir von Dir, dass Du uns einige Deiner personenbezogenen
                        Daten zur Verfügung stellst. Wir verlangen dies, wenn es für die Erbringung
                        unserer Dienstleistungen notwendig oder gesetzlich vorgeschrieben ist.
                        Beispielsweise müssen wir Deine E-Mail-Adresse erfassen, wenn Du Dich für
                        unseren Newsletter anmeldest oder wenn Du dir ein Konto von einem
                        Administrator einrichten lässt).
                    </p>
                    <p>
                        Die Nichteinhaltung der Informationspflicht kann Folgen haben: Wir werden
                        nicht in der Lage sein, Dein Konto zu erstellen; Wir werden nicht in der
                        Lage sein, Dir unsere Dienste zur Verfügung zu stellen, etc.
                    </p>
                    <br />
                    <h2 id="Warum-und-wofür-nutzt-ihr-meine-personenbezogenen-Daten">
                        6. Warum und für was nutzt ihr meine personenbezogenen Daten?
                    </h2>
                    <p>
                        Wir müssen Deine personenbezogenen Daten für verschiedene Zwecke verwenden,
                        da wir eine rechtmäßige Grundlage dafür haben, was sein kann:
                    </p>
                    <ul>
                        <li>
                            Die Notwendigkeit, unsere Nutzungsbedingungen mit Dir einzugehen oder
                            durchzuführen <b>(vertragliche Notwendigkeit)</b>,
                        </li>
                        <li>
                            die Notwendigkeit zur Erfüllung gesetzlicher Verpflichtungen (Einhaltung
                            der Gesetze),
                        </li>
                        <li>
                            ein berechtigtes Interesse an der Verarbeitung Deiner persönlichen Daten
                            (Unser berechtigtes Interesse),
                        </li>
                        <li>
                            Du hast einer solchen Verarbeitung zugestimmt (Dein Einverständnis).
                        </li>
                    </ul>

                    <p>
                        Bitte beachte, dass du das Recht hast, deine Einwilligung zur Verarbeitung
                        der oben genannten Vorgänge jederzeit zu widerrufen oder der Verarbeitung
                        aufgrund unseres berechtigten Interesses widersprechen kannst, indem du dich
                        mit uns in Verbindung setzt oder indem du deine personenbezogenen Daten von
                        der Plattform entfernst, soweit dies möglich ist.
                    </p>
                    <p></p>
                    <p>
                        Wir sammeln und verwenden Deine oben genannten personenbezogenen Daten für
                        die folgenden Zwecke der Verarbeitung:
                    </p>

                    <Box sx={{ overflow: 'auto' }}>
                        <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                            <Table
                                style={{
                                    borderCollapse: 'collapse',
                                    border: '2px solid lightgrey',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>Zweck</b>
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>Rechtliche Grundlage</b>
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            <b>
                                                (Arten von) betroffene(n) personenbezogene(n) Daten
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            Um Dir die Möglichkeit zu bieten, Dein Profil zu
                                            personalisieren
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>Zustimmung</TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Account-Informationen
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            Um Dir unseren wöchentlichen oder täglichen Newsletter
                                            zu schicken, um Dir E-Mail-Benachrichtigungen zu
                                            schicken
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>Zustimmung</TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Account-Informationen
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            Um Dir Support-Nachrichten, Updates, Sicherheitshinweise
                                            und Account-Benachrichtigungen zu schicken
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Vertragliche Notwendigkeit
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Account-Informationen
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            Registrierung und Anmeldung auf unserer Plattform
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Vertragliche Notwendigkeit
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Account-Informationen
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            Zum Anbieten unserer Services
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Vertragliche Notwendigkeit
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Account-Informationen, zusätzliche Informationen, Deine
                                            Aktivität
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            Um Dich über Änderungen unserer Dienste zu informieren
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Vertragliche Notwendigkeit, Einhaltung der Gesetze
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Account-Informationen
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderBottom: '1px solid lightgrey' }}>
                                        <TableCell sx={this.state.cellStyle}>
                                            Zur Verwaltung unserer Plattform und für interne Zwecke
                                            wie z.B. Troubleshooting und Bug-Testing, Datenanalyse,
                                            Tests, Forschung, Analyse zur Produktentwicklung.
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Berechtigtes Interesse
                                        </TableCell>
                                        <TableCell sx={this.state.cellStyle}>
                                            Account-Informationen, zusätzliche Informationen, Deine
                                            Aktivität
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    <br />
                    <h2 id="Wie-lange-speichert-ihr-meine-personenbezogenen-Daten">
                        7. Wie lange speichert ihr meine personenbezogenen Daten?
                    </h2>
                    <p></p>
                    <p>
                        Wir speichern Deine personenbezogenen Daten so lange, wie es für den Zweck
                        erforderlich ist, für den wir sie erhalten haben, insbesondere, solange Du
                        unsere Dienste weiterhin nutzt und für einen Zeitraum von drei Jahren nach
                        der letzten Nutzung dieser Dienste oder dem letzten Kontakt mit Dir, falls
                        Du einen Account auf unserer Plattform angelegt hast.
                    </p>
                    <p>
                        Am Ende des oben genannten Zeitraums können wir Dich kontaktieren, um
                        herauszufinden, ob Du unsere Dienste weiterhin nutzen möchtest. In
                        Ermangelung einer positiven und ausdrücklichen Antwort von Dir werden Deine
                        personenbezogenen Daten gemäß den geltenden Bestimmungen gelöscht oder
                        archiviert.
                    </p>
                    <p></p>
                    <p>
                        Bitte beachte, dass auch im Fall, dass Dein Account und Deine
                        personenbezogenen Daten gelöscht werden:
                    </p>
                    <p>
                        &nbsp; &nbsp; • Deine Buchungen, Bestellungen und Projekte anonymisiert
                        gespeichert bleiben
                    </p>
                    <p></p>
                    <p>Wir speichern die Cookie-Informationen bis zu maximal fünf Tage.</p>
                    <p></p>
                    <h2 id="Mit-wem-teilt-ihr-meine-personenbezogenen-Daten">
                        8. Mit wem teilt ihr meine personenbezogenen Daten?
                    </h2>
                    <p></p>
                    <p>Wir verkaufen keine personenbezogenen Daten an Dritte.</p>
                    <p>
                        Wir teilen personenbezogene Daten mit Referenten und Lehrkräfte für die
                        vertragliche Notwendigkeit im Falle einer Buchung.
                    </p>
                    <p></p>
                    <h2 id="Transferiert-ihr-meine-personenbezogenen-Daten-an-Orte-außerhalb-der-Europäischen-Union">
                        9. Transferiert ihr meine personenbezogenen Daten an Orte außerhalb der
                        Europäischen Union?
                    </h2>
                    <p></p>
                    <p>
                        Wir ziehen es vor, Deine personenbezogenen Daten so weit wie möglich
                        innerhalb der Europäischen Union zu speichern. Beispielsweise hosten wir
                        unsere Server in der Europäischen Union.
                    </p>
                    <p></p>
                    <p>
                        Einige Deiner personenbezogenen Daten werden nur dann übermittelt, wenn
                        unsere Empfänger (z.B. unsere IT-Provider) angemessene
                        Sicherheitsvorkehrungen gemäß EU-DSGVO getroffen haben.
                    </p>
                    <p></p>
                    <p>
                        Solche angemessenen Schutzmaßnahmen können insbesondere vorgesehen werden
                        durch:
                    </p>
                    <p>
                        &nbsp; &nbsp; • Standard-Datenschutzklauseln, die von der Europäischen
                        Kommission angenommen oder genehmigt wurden;
                    </p>
                    <p>&nbsp; &nbsp; • Einhaltung eines genehmigten Verhaltenskodex;</p>
                    <p>
                        &nbsp; &nbsp; • Zertifizierung im Rahmen eines zugelassenen
                        Zertifizierungsverfahrens gemäß EU-DSGVO;
                    </p>
                    <p>
                        &nbsp; &nbsp; • Vertragsklauseln, die von der zuständigen Aufsichtsbehörde
                        genehmigt wurden.
                    </p>
                    <p></p>
                    <p>
                        Wenn Du eine Anfrage an{' '}
                        <a href="mailto:office@bewegtes-lernen-wien.at">
                            office@bewegtes-lernen-wien.at
                        </a>{' '}
                        sendest, können wir Dir Informationen zu den Empfängern und ihren
                        Sicherheitsvorkehrungen geben.
                    </p>
                    <p></p>
                    <p></p>
                    <h2 id="Wie-nutzt-ihr-Cookies">10. Wie nutzt ihr Cookies?</h2>
                    <p></p>
                    <p>
                        Ein Cookie ist eine kleine Menge Daten (Textdatei), die eine Webseite Deinen
                        Browser bittet, auf Deinem Gerät zu sichern, um Informationen über Dich zu
                        speichern, z.B. Deine Login-Informationen.
                    </p>
                    <p></p>
                    <p>
                        Wir nutzen Cookies. Erfahre mehr dazu und konfiguriere Deine Einstellungen
                        in unserer Cookie-Richtlinien.
                    </p>
                    <p></p>
                    <p></p>
                    <h2 id="">11. Was sind meine Rechte?</h2>
                    <p></p>
                    <p>
                        Durch die EU-DSGVO hast Du mehrere Rechte bezüglich Deiner personenbezogenen
                        Daten:
                    </p>
                    <p></p>
                    <p>
                        &nbsp; &nbsp; • Das Recht auf Auskunft: Du kannst Informationen darüber
                        verlangen, welche personenbezogenen Daten wir von Dir haben und Kopien
                        dieser Daten erhalten.
                    </p>
                    <p>
                        &nbsp; &nbsp; • Das Recht auf Berichtigung: Du kannst die Berichtigung von
                        ungenauen oder unvollständigen personenbezogenen Daten verlangen.
                    </p>
                    <p>
                        &nbsp; &nbsp; • Das Recht auf Löschung: Du kannst die Löschung Deiner
                        personenbezogenen Daten verlangen, wenn der Grund für die Verarbeitung nicht
                        mehr besteht, es sei denn, es besteht eine rechtliche Pflicht zur
                        Aufbewahrung.
                    </p>
                    <p>
                        &nbsp; &nbsp; • Das Recht auf Einschränkung der Verarbeitung: Du kannst die
                        Einschränkung der Verarbeitung Deiner personenbezogenen Daten verlangen,
                        wenn z.B. die Richtigkeit der Daten bestritten wird.
                    </p>
                    <p>
                        &nbsp; &nbsp; • Das Recht auf Datenübertragbarkeit: Du kannst verlangen,
                        dass wir Dir die personenbezogenen Daten, die Du uns bereitgestellt hast, in
                        einem strukturierten, gängigen und maschinenlesbaren Format übermitteln.
                    </p>
                    <p>
                        &nbsp; &nbsp; • Das Recht auf Widerspruch: Du kannst aus Gründen, die sich
                        aus Deiner besonderen Situation ergeben, jederzeit der Verarbeitung Deiner
                        personenbezogenen Daten widersprechen, sofern diese aufgrund unseres
                        berechtigten Interesses erfolgt.
                    </p>
                    <p>
                        &nbsp; &nbsp; • Das Recht, eine Beschwerde bei einer Aufsichtsbehörde
                        einzureichen: Wenn Du der Meinung bist, dass wir Deine personenbezogenen
                        Daten unrechtmäßig verarbeiten, kannst Du eine Beschwerde bei einer
                        Aufsichtsbehörde in dem EU-Mitgliedsstaat einreichen, in dem Du wohnst oder
                        arbeitest oder in dem der mutmaßliche Verstoß stattgefunden hat.
                    </p>
                    <p></p>
                    <p>
                        Wenn Du eines dieser Rechte ausüben möchtest oder Fragen zu Deinen Rechten
                        hast, kannst Du uns jederzeit unter{' '}
                        <a href="mailto:office@bewegtes-lernen-wien.at">
                            office@bewegtes-lernen-wien.at
                        </a>{' '}
                        kontaktieren.
                    </p>
                    <p></p>
                    <p></p>
                    <p id="Änderungen-an-dieser-Datenschutzerklärung">
                        Stand der Datenschutzerklärung: 05.01.2024
                    </p>
                </Stack>
            </Box>
        );
    }
}
