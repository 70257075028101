import { Container, Paper, Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import React from "react";

export class ReferentenInfoComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openInfoDialog: false,
        };
    }

    handleClickOpen = () => {
        this.setState({ openInfoDialog: true });
    };

    handleClose = () => {
        this.setState({ openInfoDialog: false });
    };

    render() {
        return (
            <Paper elevation={1} square sx={{height: '90vh'}}>
                <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                    <Typography variant="h1" color={'black'} textAlign="center">
                        Referent*innen
                    </Typography>

                    <Container>
                        <Box sx={{ marginTop: 5 }}>
                            <Typography variant="h2" color={'black'} textAlign="left">
                                Was sind Referentinnen?
                            </Typography>

                            <Typography sx={{ marginTop: 1 }} variant="body1" color={'black'} textAlign="left">
                                Referentinnen sind Spezialistinnen mit unterschiedlichen Bewegungsschwerpunkten und Sportarten.
                                Im Rahmen der Referent*innen-einheiten des bewegten Lernens, gestalten sie bewegende Erlebnisse,
                                die eine Bereicherung für Kinder und Lehrer*inne darstellen.
                                <br />
                                <br />
                                Lehrpersonen buchen Referent*innen aufgrund ihres Projektklassen-Budgets
                            </Typography>
                        </Box>

                        <Box sx={{ marginTop: 10 }}>
                            <Typography sx={{ marginBottom: 1 }} variant="h2" color={'black'} textAlign="left">
                                Interesse geweckt?
                            </Typography>
                            <Typography variant="body" color={'black'} textAlign="left">
                                Sie möchten mehr Bewegung in unseren Schulalltag bringen,
                                und Expert*in für Lernen im Bereich:
                            </Typography>
                            <Typography variant="body" color={'black'} textAlign="left">
                                <ul>
                                    <li>Gesundheitsförderung,</li>
                                    <li>Sport & Bewegung,</li>
                                    <li>Kognitives Lernen mit Bewegung,</li>
                                    <li>Energetik und Soziales</li>
                                </ul>
                            </Typography>
                            <Typography variant="body" color={'black'} textAlign="left">
                                Dann kontaktieren Sie und noch heute!
                            </Typography>

                            <Box sx={{ marginTop: 5, textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 2 }}>
                                <Box>
                                    <Button size="large" variant="contained" sx={{ backgroundColor: '#E4630D', '&:hover': { backgroundColor: '#c1540b' } }} href="mailto:office@bewegtes-lernen-wien.at?subject=Referent*in%20werden">
                                        Jetzt Referent*in werden
                                    </Button>
                                </Box>
                                <Box sx={{ marginTop: 1 }}>
                                    <Button size="medium" variant="outlined" onClick={this.handleClickOpen} sx={{ color: '#E4630D', borderColor: '#E4630D', '&:hover': { backgroundColor: '#fef0e7', borderColor: '#c1540b' } }} >
                                        Ich bin Referent*in und brauche Infos
                                    </Button>

                                    <Dialog open={this.state.openInfoDialog} onClose={this.handleClose}>
                                        <DialogTitle>{"Informationen für Referent*innen"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Alle Infos finden Sie entweder in der Cloud oder indem Sie sich <a href="/login" onClick={this.handleClose}>hier einloggen</a>.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} sx={{ color: '#E4630D', '&:hover': { backgroundColor: '#fef0e7' } }}>
                                                Schließen
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                            </Box>

                        </Box>
                    </Container>
                </Container>
            </Paper >
        )
    }
}