import { deepmerge } from '@mui/utils';
import { createTheme, useTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppBarComponent } from './components/AppBar';
import { ImpressumComponent } from './components/Impressum';
import { ProfileComponent } from './components/Profile';
import { LoginComponent } from './components/Login/Login';
import { Alert, AlertColor, CssBaseline, Snackbar, useMediaQuery } from '@mui/material';
import worker_script from './worker';
import { NotFoundComponent } from './components/NotFound';
import { get } from './global';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ColorModeContext, colorData, getDesignTokens, getThemedComponents } from './Theme';
import { initTranslations } from './Translation';
import LandingComponent from './components/Landing Page/Landing';
import { DatenschutzComponent } from './components/Datenschutz';
import { EducationComponent } from './components/Education';
import { AboutUsComponent } from './components/AboutUs/AboutUs';
import { GoalsComponent } from './components/AboutUs/Goals';
import { CooperationComponent } from './components/AboutUs/Cooperation';
import { FoundersComponent } from './components/AboutUs/Founders';
import { TeamComponent } from './components/AboutUs/Team/Team';
import { ReferentenInfoComponent } from './components/staticPages/Infos-Referenten';
import { NewsViewComponent } from './components/NewsView/NewsView';
import { ProjectViewComponent } from './components/projects/ProjectView';
import { ReservationViewComponent } from './components/reservation/ReservationView';
import { DeviceViewComponent } from './components/projects/DeviceView';
import { BookingViewComponent } from './components/projects/BookingView';
import { TeacherOverviewComponent } from './components/staticPages/Infos-Lehrpersonen';

interface ISnackbar {
    open: boolean;
    vertical: 'top' | 'bottom';
    horizontal: 'center' | 'left' | 'right';
    message: string;
    colour: AlertColor | undefined;
}

var worker: Worker;
var loggedIn: boolean = false;
var role: string = '';
var firstVisit: boolean = false;

function setPermission(logged: boolean, m: string) {
    loggedIn = logged;
    role = m;
}

initTranslations();

function App() {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

    const [snackbar, setSnackbar] = React.useState<ISnackbar>({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        message: '',
        colour: 'info',
    });
    const { vertical, horizontal, open, message, colour } = snackbar;
    const changeSnackbar = (newMessage: string, newColour: AlertColor | undefined) => {
        setSnackbar({
            open: true,
            vertical: vertical,
            horizontal: horizontal,
            message: newMessage,
            colour: newColour,
        });
    };
    const closeSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const [appBarAvatar, setAppBarAvatar] = React.useState({
        appBarAvatarHash: Date.now(),
    });

    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 760px)');
    const isMobileSmall = useMediaQuery('(max-height: 740px)');
    // const isDesktop = useMediaQuery('(min-width: 1200px)');
    const acceptedConsent = localStorage.getItem('user_consent') === 'accepted';

    React.useEffect(() => {
        if (acceptedConsent && !firstVisit) {
            get('api/v1/user/me', (data: any, status: number) => {
                if (status === 200 && data !== undefined) {
                    setPermission(
                        true,
                        data.role
                    );
                    navigate(location.pathname);
                }
            });
            serviceWorkerRegistration.register({
                onSuccess(registration) {
                    changeSnackbar('App installed!', 'info');
                    localStorage.setItem('new_update', 'install');
                },
                onUpdate(registration) {
                    changeSnackbar('App updated!\nRestart to see the change!', 'info');
                    localStorage.setItem('new_update', 'update');
                },
            });
            const type = localStorage.getItem('new_update');
            if (type !== null) {
                changeSnackbar(
                    `App ${type === 'install' ? 'installed!' : 'updated!\nRestart to see the change!'
                    }`,
                    'info',
                );
                localStorage.removeItem('new_update');
            }
            firstVisit = true;
            colorMode.saveColorMode();
        }
    });

    if (typeof worker == 'undefined') {
        worker = new Worker(worker_script);
        worker.onmessage = e => {
            let data = e.data;
            setPermission(data[0], data[1]);
        };
    }

    var href = false;
    if (window.location.pathname === '/') href = true;

    window.scrollTo({
        top: 0,
        behavior: 'instant',
    });

    return (
        <div className="App" style={{ margin: 0, padding: 0 }}>
            <AppBarComponent
                theme={theme.palette.mode}
                changeBar={changeSnackbar}
                navigate={navigate}
                loggedIn={loggedIn}
                isMobile={isMobile}
                role={role}
                appBarAvatar={appBarAvatar}
                setPerm={setPermission}
                isLandingPage={href}
            />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={5000}
                open={open}
                onClose={closeSnackbar}
                key={vertical + horizontal}
            >
                <Alert onClose={closeSnackbar} severity={colour} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <LoginComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                            setPerm={setPermission}
                        />
                    }
                />
                <Route
                    path="/projekte"
                    element={
                        <ProjectViewComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                            role={role}
                            isMobile={isMobile}
                            isMobileSmall={isMobileSmall}
                        />
                    }
                />
                <Route
                    path="/geraetepool"
                    element={
                        <DeviceViewComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                            role={role}
                            isMobile={isMobile}
                            isMobileSmall={isMobileSmall}
                        />
                    }
                />
                <Route
                    path="/referenten"
                    element={
                        <BookingViewComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                            role={role}
                            isMobile={isMobile}
                            isMobileSmall={isMobileSmall}
                        />
                    }
                />
                <Route
                    path="/buchungen"
                    element={
                        <ReservationViewComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                            role={role}
                            isMobile={isMobile}
                            isMobileSmall={isMobileSmall}
                        />
                    }
                />
                <Route
                    path="/impressum"
                    element={
                        <ImpressumComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                        />
                    }
                />
                <Route
                    path="/privacy"
                    element={
                        <DatenschutzComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                        />
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <ProfileComponent
                            navigate={navigate}
                            changeBar={changeSnackbar}
                            setAppBarAvatar={setAppBarAvatar}
                        />
                    }
                />
                <Route path="/ausbildung" element={<EducationComponent changeBar={changeSnackbar} />} />
                <Route path="/ziele" element={<GoalsComponent changeBar={changeSnackbar} />} />
                <Route path="/gründer" element={<FoundersComponent changeBar={changeSnackbar} />} />
                <Route path="/gruender" element={<FoundersComponent changeBar={changeSnackbar} />} />
                <Route path="/team" element={<TeamComponent isMobile={isMobile} changeBar={changeSnackbar} />} />
                <Route path="/kooperationen" element={<CooperationComponent changeBar={changeSnackbar} />} />
                <Route path="/werde-referent" element={<ReferentenInfoComponent changeBar={changeSnackbar} />} />
                <Route path="/über-uns" element={<AboutUsComponent changeBar={changeSnackbar} />} />
                <Route path="/ueber-uns" element={<AboutUsComponent changeBar={changeSnackbar} />} />
                <Route path="/news" element={<NewsViewComponent changeBar={changeSnackbar} />} />
                <Route path="/lehrpersonen" element={<TeacherOverviewComponent changeBar={changeSnackbar} />} />
                <Route path="/infos-lehrpersonen" element={<TeacherOverviewComponent changeBar={changeSnackbar} />} />
                <Route path="/infos" element={<TeacherOverviewComponent changeBar={changeSnackbar} />} />
                <Route path="/" element={<LandingComponent changeBar={changeSnackbar} />} />
                <Route path="*" element={<NotFoundComponent />} />
            </Routes>
            {/* <FooterComponent/> */}
        </div>
    );
}

export default function ToggleColorMode() {
    let tempTheme = localStorage.getItem('theme');
    const currentTheme =
        tempTheme == null || colorData[tempTheme] === undefined ? 'light' : tempTheme;
    const [mode, setMode] = React.useState(currentTheme);
    const colorMode = React.useMemo(
        () => ({
            switchColorMode: theme => {
                setMode(prevMode => {
                    /*localStorage.setItem(
                        'theme',
                        colorData[theme] === undefined ? prevMode : theme,
                    );*/
                    return colorData[theme] === undefined ? prevMode : theme;
                });
            },
            saveColorMode: () => {
                setMode(() => {
                    localStorage.setItem('theme', mode);
                    return mode;
                });
            },
        }),
        [mode],
    );

    const theme = React.useMemo(() => {
        const designTokens = getDesignTokens(mode);
        let newTheme = createTheme(designTokens);
        newTheme = deepmerge(newTheme, getThemedComponents(newTheme));
        newTheme = responsiveFontSizes(newTheme);
        return newTheme;
    }, [mode]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <App />
                </Router>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
