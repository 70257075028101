const { base } = require('./global');

const workercode = () => {
    function fetchData() {
        let req = new XMLHttpRequest();
        req.withCredentials = true;
        //req.open('GET', `http://localhost:7780/api/v1/user/me`, true);
        req.open('GET', `https://api.bwl.30062022.xyz/api/v1/user/me`, true);
        req.setRequestHeader('Content-Type', 'application/json');
        req.send(null);
        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            if (req.status === 200) {
                let data = undefined;
                try {
                    data = JSON.parse(req.responseText);
                } catch { }
                if (data !== undefined) {
                    postMessage([true, data.role]);
                }

                //console.log(data);
            } else {
                postMessage([false, '']);
            }
        };
        setTimeout(fetchData, 20000);
    }
    fetchData();
};

let code = workercode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));
// eslint-disable-next-line no-template-curly-in-string
code = code.replaceAll('${base}', base);

const blob = new Blob([code], { type: 'application/javascript' });
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;
