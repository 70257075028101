import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
import NewsItem from "./NewsItem";
import { get } from "../../../../global";
import { Link } from "react-router-dom";

class NewsSectionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NewsItem1: {
                content: "img/LandingPage/News/News1.jpg",
                title: "News 1",
                preview: "This is the first news item"
            },
            NewsItem2: {
                img: "",
                title: "",
                text: ""
            },
            newsAvailable: false,
            syncWorkerId: 0,
        };
        this.syncNews = this.syncNews.bind(this);
    }

    componentDidMount() {
        this.syncNews();
        const id = setInterval(this.syncNews(), 30000);
        this.setState({syncWorkerId: id});
    }

    componentWillUnmount() {
        clearInterval(this.state.syncWorkerId);
    }

    syncNews(){
        get("api/v1/mail/news/", (data, status) => {
            if (status === 200 && data.length > 0) {
                this.setState({
                    NewsItem1: data[data.length - 1],
                });
                if(data.length > 1){
                    this.setState({
                        NewsItem2: data[data.length - 2],
                    });
                }
                this.setState({
                    newsAvailable: true,
                });
            }
            else{
                this.setState({
                    newsAvailable: false,
                });
            }
        });
    }

    render() {
        return (
            <Box id="NewsSection" sx={{ mx: '2.5%', my: '5vh', backgroundColor: '#E4630D' }}>
                <Typography noWrap variant="h1" sx={{ width: 'fit-content', paddingLeft:'5%', paddingRight: '6%', py: '2%', marginBottom: '2%', backgroundColor: '#3262c2', mx: '5%' }}>News</Typography>

                <Box id="NewsSectionBackground" sx={{ mx: '2.5%', padding: '1%', height: 'fit-content', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.newsAvailable ? (
                    
                        <Box>
                            <NewsItem imageKey={1} img={this.state.NewsItem1.content} title={this.state.NewsItem1.title} text={this.state.NewsItem1.preview} />
                            {this.state.NewsItem2.img !== "" && (
                                <Box sx={{marginTop: '1%'}}>
                                    <NewsItem imageKey={2} img={this.state.NewsItem2.content} title={this.state.NewsItem2.title} text={this.state.NewsItem2.preview} />
                                </Box>
                            )}                       
                        </Box>
                        
                    ) : (
                        <Typography variant="h2">Keine News vorhanden!</Typography>
                    )}
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.newsAvailable ? (
                        <Link to={'/news'} style={{ textDecoration: 'none', alignSelf: 'center' }} onClick={this.closeAppBar}>
                            <Button variant="normal" sx={{ my: 2, display: 'block', fontSize: 13 }}>
                                mehr ansehen
                            </Button>
                        </Link>
                    ) : (
                        <Link to={'/news'} style={{ textDecoration: 'none', alignSelf: 'center' }} onClick={(event) => event.preventDefault()}>
                            <Button variant="normal" sx={{ my: 2, display: 'block', fontSize: 13 }} disabled={!this.state.newsAvailable}>
                                mehr ansehen
                            </Button>
                        </Link>
                    )}
                </Box>

            </Box>
        )
    }
}

export default NewsSectionComponent;