import { Container, Paper, Typography, Button, Box } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

export class EducationComponent extends React.Component {
    handleRedirect = url => {
        window.open(url, '_blank');
    };

    render() {
        const redirectUrl =
            'https://phwien.ac.at/hochschullehrgange/bewegung-und-gesundheitsfoerderung-als-entwicklungsmotor/';

        return (
            <Container sx={{ mt: 3, p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Typography variant="h1" color={'black'} textAlign="center">
                    Ausbildung
                </Typography>
                <Typography variant="h2" color={'black'} textAlign="center">
                    Bewegung und Gesundheitsförderung als Entwicklungsmotor
                </Typography>

                <Container>
                    <Box sx={{ marginTop: 10 }}>
                        <Grid container rowSpacing={5}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color={'black'} textAlign="left">
                                    Zielgruppe(n):
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                Zielgruppe sind ausgebildete Elementarpädagog*innen sowie Personen
                                mit einem abgeschlossenen Studium Lehramt Primarstufe bzw.
                                Lehramtsstudium für Volks- und Sonderschullehrer*innen.
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" color={'black'} textAlign="left">
                                    Qualifikationsprofil:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ul>
                                    <li>
                                        Studierende des Hochschullehrgangs Bewegung und
                                        Gesundheitsförderung erwerben eine
                                        wissenschaftlich-berufsfeldbezogene Zusatzqualifikation, die
                                        sowohl Pädagog:innen in elementarpädagogischen Einrichtungen
                                        anleitet, Lern- und Entwicklungsumgebungen entsprechend zu
                                        gestalten, als auch klassenführende Lehrpersonen an
                                        Pflichtschulen unterstützt, ihren Unterricht entsprechend zu
                                        konzipieren, sowie für die Leitung bewegungsorientierter
                                        unverbindlicher Übungen qualifiziert.
                                    </li>
                                    <li>
                                        Studierende erwerben im Hochschullehrgang somit
                                        fachdidaktisches Wissen und Kompetenzen bezüglich
                                        bewegungsorientierter, gesundheitsfördernder
                                        Gestaltungsmöglichkeiten in elementarpädagogischen
                                        Einrichtungen und in allen Unterrichtsfächern der
                                        Primarstufe.
                                    </li>
                                    <li>
                                        Absolvent*innen sind laut Bildungsdirektion Wien zudem
                                        berechtigt, Klassen mit dem Schwerpunkt „Bewegtes Lernen –
                                        Gesundheitsförderung“ zu leiten, sowie als Koordinator:innen
                                        für Bewegung, Sport und Gesundheitsförderung im Bereich der
                                        Primarstufe tätig zu werden.
                                    </li>
                                </ul>
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" color={'black'} textAlign="left">
                                    Dauer: 2 Semester
                                </Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" color={'black'} textAlign="left">
                                    Voraussetzungen:
                                </Typography>
                            </Grid>
                            <Grid xs={12}>
                                <ul>
                                    <li>
                                        abgeschlossene Ausbildung als Elementarpädagog*in (Abschluss
                                        an einer Bildungsanstalt für Elementarpädagogik (BAfEP) bzw.
                                        abgeschlossener Hochschullehrgang Elementarpädagogik (60
                                        ECTS))
                                    </li>
                                    <li>
                                        abgeschlossenes Studium Lehramt Primarstufe bzw.
                                        Lehramtsstudium für Volks- und/oder Sonderschule
                                    </li>
                                    <li>
                                        bestehendes Beschäftigungsverhältnis im abgeschlossenen
                                        Ausbildungsbereich zum Zeitpunkt der Anmeldung
                                    </li>
                                    <li>
                                        Sprachkenntnisse auf dem Referenzniveau C1 des Gemeinsamen
                                        Europäischen Referenzrahmens für Sprachen entsprechend der
                                        Empfehlung des Ministerkomitees des Europarates an die
                                        Mitgliedstaaten Nr. R (98) 6 vom 17. März 1998 zum
                                        Gemeinsamen Europäischen Referenzrahmen für Sprachen (GER)
                                    </li>
                                </ul>
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" color={'black'} textAlign="left">
                                    ECTS-Credits: 15
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Container sx={{ mb: 2, p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                    <Box
                        sx={{
                            marginTop: 5,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 2,
                        }}
                    >
                        <Box>
                            <Button
                                size="large"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E4630D',
                                    '&:hover': { backgroundColor: '#c1540b' },
                                }}
                                onClick={() => this.handleRedirect(redirectUrl)}
                            >
                                Jetzt anmelden
                            </Button>
                        </Box>
                        <Box sx={{ my: 1 }}>
                            <Button
                                size="medium"
                                variant="outlined"
                                sx={{
                                    color: '#E4630D',
                                    borderColor: '#E4630D',
                                    '&:hover': {
                                        backgroundColor: '#fef0e7',
                                        borderColor: '#c1540b',
                                    },
                                }}
                                onClick={() => this.handleRedirect(redirectUrl)}
                            >
                                mehr erfahren
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Container>
        );
    }
}
