import { Container, Paper, Typography, Box } from "@mui/material";
import React from "react";

export class TeacherOverviewComponent extends React.Component {
    render() {
        return (
            <Paper elevation={1} square sx={{minHeight: '90vh'}}>
                <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                    <Typography variant="h1" color={'black'} textAlign="center">
                        Lehrpersonen
                    </Typography>

                    <Container>
                        <Box>
                            <Typography variant="h2" color={'black'} textAlign="center">
                                Was sind Referentinnen?
                            </Typography>
                        
                            
                        </Box>
                    </Container>

                </Container>
            </Paper>
        )
    }
}