import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Component } from "react";

export class DescriptionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            img_URL: "img/LandingPage/Description.png",
        };
    }

    render(){
        return(
            <Box sx={{ mx: '2.5%', my: '5vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <center><img alt="Beschreibung-Bild" src={this.state.img_URL} style={{ maxWidth: '100%', maxHeight: '100%'}} /></center>
                <Typography>
                Kinder besitzen einen natürlichen Bewegungsdrang. Mit Bewegung lernen sie die Welt rund um sich
                begreifen. Genau hier setzt Bewegtes Lernen in der Volksschule an: Bewegung wird beim Lesen, Schreiben,
                Rechnen oder bei Sachthemen ganz natürlich in den Unterricht integriert. Inhalte, die auf diese Art
                gelernt werden, verankern sich besser im Gedächtnis des Kindes. Bewegtes Lernen steigert die
                Aufmerksamkeit, verbessert die motorischen Fähigkeiten und ganz nebenbei lernen die Schüler*innen
                ihre körperlichen Fähigkeiten und Grenzen kennen. Der beste Grundstein für ein hohes
                Gesundheitsbewusstsein und ein gutes Selbstmanagement.
                </Typography>
            </Box>
        )
    }
}
