import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    TextField,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { get, post } from '../global';
import { Stack } from '@mui/system';

export class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: '',
            email: '',
            fname: '',
            lname: '',
            phoneNr: '',
            temail: '',
            tfname: '',
            tlname: '',
            tphone: '',
            newPassword: '',
            editMode: false,
            loaded: false,
        };
        this.fetchProfile = this.fetchProfile.bind(this);
        this.editProfile = this.editProfile.bind(this);
    }
    componentDidMount() {
        this.fetchProfile();
    }
    render() {
        return (
            <Paper spacing={0} direction="column" sx={{ p: 3, borderRadius: 0 }}>
                {this.state.loaded ? (
                    <Stack
                        direction={'column'}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: { xs: 0, md: 3 }, mb: { xs: 5, md: 3 } }}
                    >
                        {!this.state.editMode && (
                            <List sx={{ bgcolor: 'background.paper', mx: 'auto' }}>
                                <ListItem
                                    sx={{
                                        display: 'block',
                                        mx: 'auto',
                                        mb: 1,
                                        width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                                    }}
                                >
                                    <ListItemText primary="Email" secondary={this.state.email} />
                                </ListItem>
                                <ListItem
                                    sx={{
                                        display: 'block',
                                        mx: 'auto',
                                        mb: 1,
                                        width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                                    }}
                                >
                                    <ListItemText
                                        primary="Name"
                                        secondary={this.state.fname + ' ' + this.state.lname}
                                    />
                                </ListItem>
                                <ListItem
                                    sx={{
                                        display: 'block',
                                        mx: 'auto',
                                        mb: 2,
                                        width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                                    }}
                                >
                                    <ListItemText
                                        primary="Mobilnummer"
                                        secondary={this.state.phoneNr}
                                    />
                                </ListItem>
                                <ListItem
                                    sx={{
                                        display: 'block',
                                        mx: 'auto',
                                        width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                                    }}
                                >
                                    <EditIcon
                                        onClick={() =>
                                            this.setState({
                                                editMode: true,
                                                temail: this.state.email,
                                                tfname: this.state.fname,
                                                tlname: this.state.lname,
                                                tphone: this.state.phoneNr,
                                            })
                                        }
                                    />
                                </ListItem>
                            </List>
                        )}
                        {this.state.editMode && (
                            <Stack
                                direction={'column'}
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{ mt: { xs: 1, md: 3 }, mb: { xs: 5, md: 3 } }}
                            >
                                <Typography variant="h6">{this.state.email}</Typography>
                                <Typography variant="h6">
                                    {this.state.fname + ' ' + this.state.lname}
                                </Typography>
                                <TextField
                                    label="Email"
                                    variant="standard"
                                    sx={{
                                        mx: 'auto',
                                        mt: 2,
                                        mb: 2,
                                        width: { xs: '40vw', sm: '30vw', md: '20vw', lg: '15vw' },
                                    }}
                                    onChange={e => this.setState({ temail: e.target.value })}
                                    value={this.state.temail}
                                />
                                <TextField
                                    label="Vorname"
                                    variant="standard"
                                    sx={{
                                        mx: 'auto',
                                        mt: 2,
                                        mb: 2,
                                        width: { xs: '40vw', sm: '30vw', md: '20vw', lg: '15vw' },
                                    }}
                                    onChange={e => this.setState({ tfname: e.target.value })}
                                    value={this.state.tfname}
                                />
                                <TextField
                                    label="Nachname"
                                    variant="standard"
                                    sx={{
                                        mx: 'auto',
                                        mt: 2,
                                        mb: 2,
                                        width: { xs: '40vw', sm: '30vw', md: '20vw', lg: '15vw' },
                                    }}
                                    onChange={e => this.setState({ tlname: e.target.value })}
                                    value={this.state.tlname}
                                />
                                <TextField
                                    label="Mobilnummer"
                                    variant="standard"
                                    sx={{
                                        mx: 'auto',
                                        mt: 2,
                                        mb: 2,
                                        width: { xs: '40vw', sm: '30vw', md: '20vw', lg: '15vw' },
                                    }}
                                    onChange={e => this.setState({ tphone: e.target.value })}
                                    value={this.state.tphone}
                                />
                                <Typography variant="body2" sx={{ mt: 3 }}>
                                    Passwort ändern
                                </Typography>
                                <TextField
                                    label="Neues Passwort"
                                    type="password"
                                    variant="standard"
                                    sx={{
                                        display: 'block',
                                        mx: 'auto',
                                        mt: 2,
                                        mb: 2,
                                        width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                                    }}
                                    onChange={e => this.setState({ newPassword: e.target.value })}
                                    value={this.state.newPassword}
                                    error={
                                        this.state.newPassword.trim().length > 0 &&
                                        this.state.newPassword.trim().length < 8
                                    }
                                    helperText="Muss min. 8 Zeichen lang sein!"
                                    fullWidth
                                />
                                <Stack
                                    direction={'row'}
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <CheckIcon onClick={this.editProfile} />
                                    <CloseIcon onClick={() => this.setState({ editMode: false })} />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                ) : (
                    <div>
                        <Skeleton
                            variant="text"
                            sx={{
                                display: 'block',
                                mx: 'auto',
                                mt: 2,
                                mb: 2,
                                width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                display: 'block',
                                mx: 'auto',
                                mt: 2,
                                mb: 2,
                                width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                display: 'block',
                                mx: 'auto',
                                mt: 2,
                                mb: 2,
                                width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                display: 'block',
                                mx: 'auto',
                                mt: 2,
                                mb: 2,
                                width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                display: 'block',
                                mx: 'auto',
                                mt: 2,
                                mb: 2,
                                width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                            }}
                        />
                        <Skeleton
                            variant="circular"
                            width={40}
                            height={40}
                            sx={{
                                display: 'block',
                                mx: 'auto',
                                mt: 2,
                                mb: 2,
                                width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                            }}
                        />
                    </div>
                )}
            </Paper>
        );
    }
    fetchProfile() {
        this.setState({ loaded: false });
        get('api/v1/user/me', (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    uuid: data.uuid,
                    email: data.email,
                    fname: data.firstName,
                    lname: data.lastName,
                    phoneNr: data.phoneNr,
                    loaded: true,
                });
            } else {
                this.props.navigate('/login');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }
    editProfile() {
        let npw = this.state.newPassword.trim().length;
        if (npw > 0 && npw < 8) {
            return;
        }
        let userObj = {
            uuid: this.state.uuid,
            email: this.state.temail,
            firstName: this.state.tfname,
            lastName: this.state.tlname,
            phoneNr: this.state.tphone,
            password: this.state.newPassword,
        };
        post('api/v1/user/me', userObj, (data, status) => {
            if (status === 200) {
                this.setState({
                    editMode: false,
                    temail: '',
                    tfname: '',
                    tlname: '',
                    tphone: '',
                    newPassword: '',
                });
                this.props.changeBar('Profil erfolgreich geändert!', 'success');
                this.fetchProfile();
            } else {
                this.props.changeBar('Profil konnte nicht geändert werden!', 'error');
            }
        });
    }
}
