import { Container, Typography, Box } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

export class CooperationComponent extends React.Component {
    render() {
        return (
            <Container sx={{ my: 3, p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Typography variant="h1" color={'black'} textAlign="center">
                    Kooperationen
                </Typography>
                <Typography variant="h2" color={'black'} textAlign="center">
                    Projektträger und Kooperationspartner
                </Typography>

                <Container>
                    <Box>
                        <Box sx={{ marginTop: 7 }}>
                            <Typography variant="h3" color={'black'} textAlign="center">
                                Bildungsdirektion Wien
                            </Typography>

                            <Stack sx={{ p: { sm: 2, md: 3, lg: 5, xl: 10 } }} alignItems="center">
                                <img
                                    src="img/MirWurscht/Bildungsdirektion.png"
                                    alt="Logo - Bildungsdirektion"
                                />
                            </Stack>
                            <Typography variant="body" color={'black'}>
                                Um die Umsetzung des Bewegten Lernens in der Volksschule zu
                                gewährleisten, wird der Schwerpunktklasse nach Möglichkeit während 2
                                Jahren innerhalb der ersten 4 Unterrichtsjahre eine zusätzliche
                                Unterrichtsstunde mit der Klassenlehrer/in angeboten.
                                <br />
                                <br />
                                Die Lehrerfortbildung zum/r „LehrerIn für Bewegtes Lernen und
                                Gesundheitstraining“, deren Konzeption von der Begründerin des
                                Projekts entworfen wurde, wird von der PH-Wien, 1100 Wien,
                                Grenzackerstr. als Lehrgang angeboten.
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Box sx={{ marginTop: 12 }}>
                            <Typography variant="h3" color={'black'} textAlign="center">
                                Sportastic
                            </Typography>

                            <Stack sx={{ p: { sm: 2, md: 3, lg: 5, xl: 10 } }} alignItems="center">
                                <img src="img/MirWurscht/Sportastic.png" alt="Logo - Sportastic" />
                            </Stack>
                            <Typography variant="body" color={'black'}>
                                Um die Umsetzung des Bewegten Lernens in der Volksschule zu
                                gewährleisten, wird der Schwerpunktklasse nach Möglichkeit während 2
                                Jahren innerhalb der ersten 4 Unterrichtsjahre eine zusätzliche
                                Unterrichtsstunde mit der Klassenlehrer/in angeboten.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Container>
        );
    }
}
