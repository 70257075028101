import { Container, Typography, Box } from '@mui/material';
import React from 'react';

export class FoundersComponent extends React.Component {
    render() {
        return (
            <Container sx={{ my: 3, p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Typography variant="h1" color={'black'} textAlign="center">
                    Kooperationen
                </Typography>
                <Typography variant="h2" color={'black'} textAlign="center">
                    Projektträger und Kooperationspartner
                </Typography>

                <Container>
                    <Box>
                        <Typography
                            sx={{ marginTop: 8, marginBottom: 2 }}
                            variant="h3"
                            color={'black'}
                            textAlign="center"
                        >
                            Marina Thuma, MA MBA
                        </Typography>
                        <Typography variant="body" color={'black'} textAlign="center">
                            Langjährige Unterrichtstätigkeit als Volksschullehrerin in den Bereichen
                            Integration, dynamisches Förderkonzept, Erziehung, Begleitunterricht für
                            Schüler mit nichtdeutscher Muttersprache, Arbeiten mit Studierenden der
                            Pädagogischen Akademie, Referentin des PI Wien, Dozentin der PH Wien,
                            Koordinatorin für Bewegung und Sport/APS an der PH Wien, Autorin
                            zahlreicher Fachartikel und Schulbücher.
                        </Typography>
                    </Box>
                </Container>
            </Container>
        );
    }
}
