import { alpha } from '@mui/system';
import { Colors, ThemeEntry } from '../Theme';

export default function LightTheme(
    blue: Colors,
    blueDark: Colors,
    grey: Colors,
    success: Colors,
    warning: Colors,
): ThemeEntry {
    return {
        primary: blue,
        divider: grey[100],
        text: {
            primary: grey[900],
            secondary: grey[700],
        },
        success: '#1AA251',
        h1: {
            color: blueDark[900],
        },
        h2: {
            color: blueDark[700],
        },
        h5: {
            color: blue.main,
        },
        button: {
            code: {
                color: grey[800],
                borderColor: grey[300],
                backgroundColor: grey[50],
                webkit: {
                    backgroundColor: blue[50],
                    color: blue.main,
                },
                icon: grey[700],
            },
            link: {
                color: '#fff',
            },
            normal: {
                color: '#fff',
                backgroundColor: '#3263C2',
            },
            icon: {
                borderColor: grey[200],
                color: blue[500],
                hover: {
                    borderColor: grey[300],
                    backgroundColor: grey[50],
                },
            },
            dashed: {
                border: `3px dashed ${grey[300]}`,
            },
        },
        menu: {
            backgroundColor: '#fff',
            borderColor: grey[200],
            item: {
                hover: grey[50],
                focus: grey[50],
                selectedColor: blue[600],
                selectedBackground: alpha(blue[100], 0.6),
            },
        },
        popover: {
            boxShadow: 'rgba(170, 180, 190, 0.3)',
        },
        container: {
            backgroundColor: '#fff',
            color: grey[900],
        },
        link: {
            color: '#fff',
            hoverColor: '#fff',
        },
        chip: {
            outlined: {
                color: grey[900],
                backgroundColor: 'transparent',
                borderColor: grey[200],
            },
            filled: {
                color: grey[800],
                backgroundColor: blue[100],
                borderColor: blue[200],
                hover: {
                    backgroundColor: blue[200],
                },
            },
            light: {
                standard: {
                    color: blue[700],
                    backgroundColor: alpha(blue[100], 0.3),
                },
                warning: {
                    color: warning[900],
                    backgroundColor: warning[100],
                },
                success: {
                    color: success[900],
                    backgroundColor: success[100],
                },
            },
            deleteIcon: {
                color: blue[700],
                hoverColor: blue[900],
            },
        },
        listItemButton: {
            color: grey[700],
            hover: {
                backgroundColor: grey[50],
            },
            selected: {
                color: blue[500],
                borderColor: blue[500],
                backgroundColor: blue[50],
                hover: {
                    backgroundColor: blue[100],
                },
            },
        },
        appBar: {
            backgroundImage: 'none',
            backgroundColor: '#E4630D',
            href: '#fffff',     //none
            color: '#fffff',       //grey[800]
            borderColor: grey[200],
            boxShadow: 'rgba(170, 180, 190, 0.3)',
            linkButtonHoverShadow: 'rgba(170, 180, 190, 0.3)',      //rgba(170, 180, 190, 0.3)
        },
        
        paper: {
            root: {
                backgroundImage: 'none',
                backgroundColor: '#fff',
                href: 'none',
            },
            outlined: {
                display: 'block',
                borderColor: grey[200],
                linkButtonHoverShadow: 'rgba(170, 180, 190, 0.3)',
            },
        },
        toggleButtonGroup: {
            backgroundColor: '#fff',
        },
        toggleButton: {
            color: grey[700],
            borderColor: grey[200],
            selected: {
                color: blue[500],
                borderColor: blue[500],
                backgroundColor: blue[50],
                hover: {
                    backgroundColor: blue[100],
                },
            },
        },
        switch: {
            track: {
                backgroundColor: grey[400],
            },
        },
        paginationItem: {
            color: grey[700],
            borderColor: grey[200],
            selected: {
                color: blue[500],
                borderColor: blue[500],
                backgroundColor: blue[50],
                hover: {
                    backgroundColor: blue[100],
                },
            },
        },
    };
}
