import { Container, Paper, Typography } from "@mui/material";
import React from "react";

export class NotFoundComponent extends React.Component {
    render() {
        return (
            <Paper elevation={1} square>
                <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                    <Typography variant="h1" textAlign="center">
                        404 Nicht gefunden!
                    </Typography>
                </Container>
            </Paper>
        )
    }
}
