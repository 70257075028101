import { Box, Container, Paper, Typography } from '@mui/material';
import React from 'react';

export class ImpressumComponent extends React.Component {
    render() {
        return (
            <Paper
                style={{ height: '70vh', border: 'none', boxShadow: 'none' }}
                square
            >
                <Container sx={{ p: 3, mt: 3 }}>
                    <Box sx={{ flexGrow: 1, border: 'none' }}>
                        <Typography
                            variant="h4"
                            noWrap
                            component="div"
                            sx={{ my: 2 }}
                        >
                            IMPRESSUM
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ my: 1 }}
                        >
                            Bewegtes Lernen – Gesundheitsförderung in Volksschulen / Wien
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ my: 1 }}
                        >
                            Claudia Slavik, MA
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ my: 1 }}
                        >
                            Dipl. Päd. Marina Thuma, MA MBA
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ my: 1 }}
                        >
                            Bendagasse 1-2
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ my: 1 }}
                        >
                            1230 Wien
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ my: 1 }}
                        >
                            E-Mail.: <a href="mailto:office@bewegtes-lernen-wien.at">office@bewegtes-lernen-wien.at</a>
                        </Typography>
                    </Box>
                </Container>
            </Paper>
        );
    }
}

export default ImpressumComponent;
