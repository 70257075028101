import { Button, Container, Typography } from '@mui/material';
import React from 'react';
import { get } from '../../global';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'projectNr', headerName: 'NR', width: 170 },
    { field: 'classRoom', headerName: 'Klasse', width: 140 },
    { field: 'createdAt', headerName: 'Angelegt am', width: 140 },
    { field: 'durationInDays', headerName: 'Dauer (Tage)', type: 'number', width: 140 },
];

export class ProjectViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
        };
        this.fetchProjects = this.fetchProjects.bind(this);
    }

    componentDidMount() {
        this.fetchProjects();
    }

    render() {
        return (
            <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Stack
                    direction="column"
                    justifyContent="left"
                    height="10vh"
                    alignItems="left"
                    spacing={2}
                >
                    <Stack
                        direction="row"
                        justifyContent="left"
                        height="10vh"
                        alignItems="left"
                        spacing={2}
                    >
                        <Typography variant="h1" color={'black'}>
                            Ihre Projekte
                        </Typography>
                        <Button
                            variant="normal"
                            sx={{
                                my: 2,
                                display: 'block',
                                fontSize: 13,
                                alignSelf: 'center',
                            }}
                            onClick={() => this.props.navigate('/geraetepool')}
                        >
                            Bestellen / Buchen
                        </Button>
                    </Stack>

                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            getRowId={row => row.projectNr}
                            rows={this.state.projects}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 30, 50, 100]}
                            rowSelection={false}
                        />
                    </div>
                </Stack>
            </Container>
        );
    }

    fetchProjects() {
        get('api/v1/teacher/projects/', (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    projects: data,
                });
            } else if (status === 401) {
                this.props.navigate('/login');
                this.props.changeBar('Melde dich erneut an!', 'error');
            } else {
                this.props.navigate('/');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }
}
