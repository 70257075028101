import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Component } from 'react';
import CardSectionComponent from './Components/CardSection';
import NewsSectionComponent from './Components/News/NewsSection';
import { DescriptionComponent } from './Components/Description';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { postType } from '../../global';

class LandingComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            consentNews: false,
            img_URL: 'img/LandingPage/hero_background.jpg',
            expanded: false,
        };
        this.email = this.email.bind(this);
        this.subscribeNewsletter = this.subscribeNewsletter.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /*
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    */

    handleChange(panel) {
        return (event, isExpanded) => {
            this.setState({ expanded: isExpanded ? panel : false });
        };
    }

    //TODO: fix ze box in ze Hero
    render() {
        return (
            <div id="LandingPage" style={{ position: 'static' }}>
                <div
                    id="Hero"
                    style={{
                        backgroundImage: `url(${this.state.img_URL})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '90vh',
                    }}
                >
                    <Box sx={{ opacity: '0%' }}>Ich bin nur für Design da</Box>
                    <div
                        style={{
                            marginTop: '5vh',
                            marginLeft: '5%',
                            padding: '1%',
                            backgroundColor: '#E4630D',
                            width: 'min-content',
                        }}
                    >
                        <Typography
                            noWrap
                            variant="h1"
                            sx={{
                                color: 'white',
                                fontSize: '7vw',
                            }}
                        >
                            Bewegtes Lernen
                        </Typography>
                        <Typography
                            noWrap
                            variant="h2"
                            sx={{
                                color: 'white',
                                fontSize: '4.5vw',
                            }}
                        >
                            Gesundheitsförderung
                        </Typography>
                    </div>
                </div>

                <CardSectionComponent navigate={this.props.navigate} />

                <Grid container spacing={1}>
                    <Grid item xs={6.5}>
                        <DescriptionComponent />
                    </Grid>
                    <Grid item xs={5.5}>
                        <NewsSectionComponent />
                    </Grid>
                </Grid>

                <Box sx={{ width: '80%', mx: '10%'}}>
                    <Accordion expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        /*aria-controls="panel1bh-content"
                        id="panel1bh-header"*/
                        >
                            <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                Was ist Bewegtes Lernen – Gesundheitsförderung?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Vielleicht begann in den Wandelhallen zu Aristoteles Zeiten bereits die Geschichte des Bewegten Lernens?
                                Der Gedanke des Lernens mit und durch Bewegung zeigt jedenfalls bis heute Wirkung.
                                Es lernt nicht nur der Kopf, sondern der ganze Körper. Der Unterricht in der Schule wird so gestaltet,
                                dass die Kinder Lerninhalte erleben und so schneller Zusammenhänge erkennen können. Schüler*innen werden
                                nicht belehrt, sondern motiviert, selbstbestimmt zu leben und ihre Umwelt aktiv mitzugestalten.
                                Bewegtes Lernen versteht sich als ergänzende Form des traditionellen Lernens und kann in vielen Fächern
                                ngewendet werden.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        // aria-controls="panel2bh-content"
                        // id="panel2bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>Wie funktioniert Bewegtes Lernen?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography paragraph>
                                Der Verein Bewegtes Lernen bietet Pädagog*innen eine Ausbildung zur Lehrer*in für Bewegtes Lernen und
                                Gesundheitsförderung (Gesundheitspädagog/innen). Diese Zusatzausbildung ermöglicht eine Schwerpunktklasse
                                für Bewegtes Lernen an einer Volks- oder Sonderschule. Schwerpunktklassen erhalten zusätzliche Leistungen,
                                die Zusammenarbeit mit Expert*innen für Ernährung, Bewegung, Sensomotorik, Energetik, Gesundheitschecks u.vm.
                            </Typography>
                            <Typography paragraph>
                                Zielsetzungen: kognitives Lernen mit und durch Bewegung, Gesundheitsförderung,
                                Prävention, Verbesserung der sportmotorischen Fähigkeiten und Fertigkeiten, Förderung der sozialen Kompetenz.
                            </Typography>
                            <Typography paragraph>
                                Inhalte: Lernen mit Bewegung – Lernen durch Bewegung, Lernen über alle Sinne mit Lust und Freude,
                                Lernen mit dem ganzen Körper, Lernsituation durch ganzheitliches Handeln erfahren, Lerninhalte
                                sinnbezogen und körpernah erleben. Den Unterricht so zu gestalten, dass die Schüler/innen die
                                Lerninhalte durch Erleben, Denken und Handeln erfahren und Zusammenhänge erkennen können, ist das
                                Hauptanliegen der Initiative „Bewegtes Lernens und Gesundheitsförderung“. Bewegtes Lernen ist eine
                                ergänzende Form zum traditionellen Lernen!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel3'} onChange={this.handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        // aria-controls="panel3bh-content"
                        // id="panel3bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Wem nützt Bewegtes Lernen?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <strong>– den Kindern</strong>
                            </Typography>
                            <Typography paragraph>
                                Bewegtes Lernen fördert nachhaltig das Gesundheits- und Ernährungsbewusstsein der Kinder. Es verbessert ihre Lernleistungen durch erhöhte Merk- und Konzentrationsfähigkeit. Haltungsschäden wird vorgebeugt, zusätzlich lernen die Kinder besser mit Aggressionen und Gefahrenquellen umzugehen.
                            </Typography>
                            <Typography>
                                <strong>– den Eltern</strong>
                            </Typography>
                            <Typography paragraph>
                                Die seelische und körperliche Ausgeglichenheit der Kinder unterstützt ein positives Familienklima. Verstärkte Möglichkeit zur Einbeziehung der Eltern in die Projektarbeit (z.B. bei Projekt-, Sport- oder Gesundheitstagen).
                            </Typography>
                            <Typography>
                                <strong>– den Lehrer/innen</strong>
                            </Typography>
                            <Typography paragraph>
                                Durch besseres Arbeitsklima in der Klasse. Eigennutz durch besseren Wissensstand (Ausbildung) und zusätzliche Impulse durch Weiterbildungsveranstaltungen und Reflexionen.
                            </Typography>
                            <Typography >
                                <strong>– dem Setting Schule</strong>
                            </Typography>
                            <Typography paragraph>
                                Durch Aufwertung des Schulstandortes, Schaffung eines positiven Arbeitsklimas in der gesamten Schule durch Gemeinschaftsförderung über die Projektidee, Multiplikatoren-Wirkung durch alle in der Schule beteiligten Personen, Impulssetzung durch die Schwerpunktklassen.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Stack direction={'column'} alignItems={'center'}>
                    <Typography
                        noWrap
                        variant="h2"
                        sx={{
                            fontSize: '4.5vw',
                        }}
                    >
                        Newsletter
                    </Typography>
                    <TextField
                        label={'Email'}
                        placeholder={'Email'}
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.email}
                        error={this.state.email.length < 8}
                        sx={{
                            display: 'block',
                            mx: 'auto',
                            mt: 2,
                            mb: 0.5,
                            width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                        }}
                        fullWidth
                        required
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="remember-me"
                                color="primary"
                                value={this.state.consentNews}
                                onChange={e => this.setState({ consentNews: e.target.checked })}
                            />
                        }
                        label={
                            <Typography variant="h6" component={'p'}>
                                Ich stimme der{' '}
                                <Link
                                    href={'/privacy'}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: 'blue',
                                        alignSelf: 'center',
                                    }}
                                >
                                    Datenschutzerklärung
                                </Link>{' '}
                                zu.
                            </Typography>
                        }
                        sx={{
                            display: 'block',
                            mx: 'auto',
                            width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                        }}
                    />
                    <Button
                        type="submit"
                        onClick={this.subscribeNewsletter}
                        disabled={!this.state.consentNews}
                        color="primary"
                        variant="contained"
                        sx={{
                            display: 'block',
                            mx: 'auto',
                            mt: 2,
                            mb: 2,
                            width: { xs: '80vw', sm: '60vw', md: '40vw', lg: '30vw' },
                        }}
                    >
                        Newsletter anmelden
                    </Button>
                </Stack>
            </div>
        );
    }

    email(event) {
        this.setState({
            email: event.target.value,
        });
    }

    subscribeNewsletter() {
        const email = this.state.email;
        postType(
            'api/v1/mail/newsletter/subscribe',
            'application/x-www-form-urlencoded',
            `email=${email}`,
            (data, status) => {
                if (status === 200) {
                    this.props.changeBar('Anmeldung zum Newsletter erfolgt!', 'success');
                } else {
                    this.props.changeBar(
                        'Du hast dich bereits zum Newsletter angemeldet!',
                        'error',
                    );
                }
            },
        );
    }
}



export default LandingComponent;
