import { Container, Paper, Typography, Box, Grid } from "@mui/material";
import React from "react";
import { TeamMemberComponent } from "./TeamMember";

export class TeamComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            direction: "row"
        };
    }

    componentDidMount() {
        if (this.props.isMobile) {
            this.setState({ direction: "column" });
        }
    }

    render() {
        return (
            <Paper elevation={1} square sx={{ height: '90vh'}}>
                <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                    <Typography variant="h1" color={'black'} textAlign="center" sx={{ my: '5%' }}>
                        Das Team
                    </Typography>

                    <Container>
                        <Box>
                            {this.props.isMobile ? (
                                <>
                                    <TeamMemberComponent isLead={true} role="BWL-GF Projektorganisation, Vereinsmanagement" img="img/Team/Claudsch.png" alt="Foto - Claudia Slavik" name="Claudia Slavik" title="MA." href="mailto: office@bewegtes-lernen-wien.at" isMobile={this.props.isMobile} />

                                    <TeamMemberComponent isLead={false} role="Kassierin / Verechnung" img="img/Team/Kathrin.png" alt="Foto - Nina-Katrin Wojner" name="Nina-Katrin Wojner" title="BEd" href="mailto:nina.katrin@gmail.com" isMobile={this.props.isMobile} />

                                    <Box sx={{ paddingBottom: '5%' }}>
                                        <TeamMemberComponent isLead={false} role="Gerätepool-administration & Terminkoordination" img="img/Team/Brigitte.png" alt="Foto - Brigitte Nuhsbaumer" name="Brigitte Nuhsbaumer" title="BEd MA." href="mailto: brigitte.nuhsbaumer@icloud.com" isMobile={this.props.isMobile} />
                                    </Box>
                                </>
                            ) : (
                                <Grid container direction={this.state.direction} columnSpacing={5} alignItems="end" sx={{maxWidth: '100vw'}}>

                                    <TeamMemberComponent isLead={false} role="Kassierin / Verechnung" img="img/Team/Kathrin.png" alt="Foto - Nina-Katrin Wojner" name="Nina-Katrin Wojner" title="BEd" href="mailto:nina.katrin@gmail.com" isMobile={this.props.isMobile} />

                                    <TeamMemberComponent isLead={true} role="BWL-GF Projektorganisation, Vereinsmanagement" img="img/Team/Claudsch.png" alt="Foto - Claudia Slavik" name="Claudia Slavik" title="MA." href="mailto: office@bewegtes-lernen-wien.at" isMobile={this.props.isMobile} />

                                    <TeamMemberComponent isLead={false} role="Gerätepool-administration & Terminkoordination" img="img/Team/Brigitte.png" alt="Foto - Brigitte Nuhsbaumer" name="Brigitte Nuhsbaumer" title="BEd MA." href="mailto: brigitte.nuhsbaumer@icloud.com" isMobile={this.props.isMobile} />
                                
                                </Grid>
                            )}
                        </Box>
                    </Container>
                </Container>
            </Paper>
        )
    }
}
