import { ArrowDropDownRounded } from '@mui/icons-material';
import { Theme, ThemeOptions, createTheme } from '@mui/material';
import React from 'react';
import LightTheme from './themes/light.theme';

export interface Colors {
    [key: number | string]: string;
}

interface ThemeEntries {
    [key: string]: ThemeEntry;
}

export interface ThemeEntry {
    primary: Colors;
    main?: String;
    divider: string;
    text: {
        primary: string;
        secondary: string;
    };
    background?: {
        default: string;
        paper: string;
    };
    success: string;
    h1: {
        color: string;
    };
    h2: {
        color: string;
    };
    h5: {
        color: string;
    };
    button: {
        code: {
            color: string;
            borderColor: string;
            backgroundColor: string;
            webkit: {
                backgroundColor: string;
                color: string;
            };
            icon: string;
        };
        link: {
            color: string;
        };
        normal: {
            color: string;
            backgroundColor: string;
        };
        dashed: {
            border: string;
        };
        icon: {
            borderColor: string;
            color: string;
            hover: {
                borderColor: string;
                backgroundColor: string;
            };
        };
    };
    menu: {
        backgroundColor: string;
        borderColor: string;
        item: {
            hover: string;
            focus: string;
            selectedColor: string;
            selectedBackground: string;
        };
    };
    popover: {
        boxShadow: string;
    };
    container: {
        backgroundColor: string;
        color: string;
    };
    link: {
        color: string;
        hoverColor: string;
    };
    chip: {
        outlined: {
            color: string;
            backgroundColor: string;
            borderColor: string;
        };
        filled: {
            color: string;
            backgroundColor: string;
            borderColor: string;
            hover: {
                backgroundColor: string;
            };
        };
        light: {
            standard: {
                color: string;
                backgroundColor: string;
            };
            warning: {
                color: string;
                backgroundColor: string;
            };
            success: {
                color: string;
                backgroundColor: string;
            };
        };
        deleteIcon: {
            color: string;
            hoverColor: string;
        };
    };
    listItemButton: {
        color: string;
        hover: {
            backgroundColor: string;
        };
        selected: {
            color: string;
            borderColor: string;
            backgroundColor: string;
            hover: {
                backgroundColor: string;
            };
        };
    };
    appBar: {
        backgroundImage: string;
        backgroundColor: string;
        href: string;
        color: string;
        borderColor: string;
        boxShadow: string;
        linkButtonHoverShadow: string;
    };
    paper: {
        root: {
            backgroundImage: string;
            backgroundColor: string;
            href: string;
        };
        outlined: {
            display: string;
            borderColor: string;
            backgroundColor?: string;
            linkButtonHoverShadow: string;
        };
    };
    toggleButtonGroup: {
        backgroundColor: string;
    };
    toggleButton: {
        color: string;
        borderColor: string;
        selected: {
            color: string;
            borderColor: string;
            backgroundColor: string;
            hover: {
                backgroundColor: string;
            };
        };
    };
    switch: {
        track: {
            backgroundColor: string;
        };
    };
    paginationItem: {
        color: string;
        borderColor: string;
        selected: {
            color: string;
            borderColor: string;
            backgroundColor: string;
            hover: {
                backgroundColor: string;
            };
        };
    };
}

export const ColorModeContext = React.createContext({
    switchColorMode: theme => {},
    saveColorMode: () => {},
});

export const blue: Colors = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    main: '#007FFF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

// FIXME - We changed the name from darkBlue to standardPallet. How the fuq does this still work????
export const standardPallet: Colors = {
    50: '#E2EDF8',
    100: '#CEE0F3',
    200: '#91B9E3',
    300: '#5090D3',
    main: '#ffffff',
    40: '#265D97',
    500: '#1E4976',
    600: '#173A5E',
    700: '#132F4C',
    800: '#001E3C',
    900: '#ffffff',
};
export const grey: Colors = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
};

export const error: Colors = {
    50: '#FFF0F1',
    100: '#FFDBDE',
    200: '#FFBDC2',
    300: '#FF99A2',
    400: '#FF7A86',
    500: '#FF505F',
    main: '#EB0014',
    600: '#EB0014',
    700: '#C70011',
    800: '#94000D',
    900: '#570007',
};
export const success: Colors = {
    50: '#E9FBF0',
    100: '#C6F6D9',
    200: '#9AEFBC',
    300: '#6AE79C',
    400: '#3EE07F',
    500: '#21CC66',
    600: '#1DB45A',
    700: '#1AA251',
    800: '#178D46',
    900: '#0F5C2E',
};
export const warning: Colors = {
    50: '#FFF9EB',
    100: '#FFF3C1',
    200: '#FFECA1',
    300: '#FFDC48',
    400: '#F4C000',
    500: '#DEA500',
    main: '#DEA500',
    600: '#D18E00',
    700: '#AB6800',
    800: '#8C5800',
    900: '#5A3600',
};

export const colorData: ThemeEntries = {
    light: LightTheme(blue, standardPallet, grey, success, warning),
};

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        code: true;
        link: true;
        dashed: true;
        normal: true;
    }
}

declare module '@mui/material/styles/createPalette' {
    interface ColorRange {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    }

    interface PaletteColor extends ColorRange {}

    interface Palette {
        primaryDark: PaletteColor;
    }
}

declare module '@mui/material/styles/createTypography' {
    interface TypographyOptions {
        fontWeightSemiBold?: number;
        fontWeightExtraBold?: number;
        fontFamilyCode?: string;
    }

    interface Typography {
        fontWeightSemiBold: number;
        fontWeightExtraBold: number;
        fontFamilyCode: string;
    }
}

const defaultTheme = createTheme();

const systemFont = [
    'Roboto'
];

export const getDesignTokens = (mode: string) =>
    ({
        palette: {
            primary: {
                ...colorData[mode].primary,
                ...colorData[mode].main,
            },
            divider: colorData[mode].divider,
            mode,
            ...(colorData[mode].background && {
                background: {
                    default: colorData[mode].background?.default,
                    paper: colorData[mode].background?.paper,
                },
            }),
            common: {
                black: '#1D1D1D',
            },
            text: {
                primary: colorData[mode].text.primary,
                secondary: colorData[mode].text.secondary,
            },
            grey,
        },
        shape: {
            borderRadius: 10,
        },
        spacing: 10,
        typography: {
            fontFamily: ['"PlusJakartaSans"', ...systemFont].join(','),
            fontFamilyCode: [
                'Consolas',
                'Menlo',
                'Monaco',
                'Andale Mono',
                'Ubuntu Mono',
                'monospace',
            ].join(','),
            fontFamilyTagline: ['"PlusJakartaSans-ExtraBold"', ...systemFont].join(','),
            fontFamilySystem: systemFont.join(','),
            fontWeightSemiBold: 600,
            fontWeightExtraBold: 800,
            h1: {
                fontFamily: ['"PlusJakartaSans-ExtraBold"', ...systemFont].join(','),
                fontSize: 'clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)',
                fontWeight: 800,
                lineHeight: 78 / 70,
                color: colorData[mode].h1.color,
            },
            h2: {
                fontFamily: ['"PlusJakartaSans-ExtraBold"', ...systemFont].join(','),
                fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
                fontWeight: 800,
                lineHeight: 44 / 36,
                color: colorData[mode].h2.color,
            },
            h3: {
                fontFamily: ['"PlusJakartaSans-Bold"', ...systemFont].join(','),
                fontSize: defaultTheme.typography.pxToRem(36),
                lineHeight: 44 / 36,
                letterSpacing: 0.2,
            },
            h4: {
                fontFamily: ['"PlusJakartaSans-Bold"', ...systemFont].join(','),
                fontSize: defaultTheme.typography.pxToRem(28),
                lineHeight: 42 / 28,
                letterSpacing: 0.2,
            },
            h5: {
                fontFamily: ['"PlusJakartaSans-Bold"', ...systemFont].join(','),
                fontSize: defaultTheme.typography.pxToRem(24),
                lineHeight: 36 / 24,
                letterSpacing: 0.1,
                color: colorData[mode].h5.color,
            },
            h6: {
                fontSize: defaultTheme.typography.pxToRem(20),
                lineHeight: 30 / 20,
            },
            button: {
                textTransform: 'initial',
                fontWeight: 700,
                letterSpacing: 0,
            },
            subtitle1: {
                fontSize: defaultTheme.typography.pxToRem(18),
                lineHeight: 24 / 18,
                letterSpacing: 0,
                fontWeight: 500,
            },
            body1: {
                fontSize: defaultTheme.typography.pxToRem(16),
                lineHeight: 24 / 16,
                letterSpacing: 0,
            },
            body2: {
                fontSize: defaultTheme.typography.pxToRem(14),
                lineHeight: 21 / 14,
                letterSpacing: 0,
            },
            caption: {
                display: 'inline-block',
                fontSize: defaultTheme.typography.pxToRem(12),
                lineHeight: 18 / 12,
                letterSpacing: 0,
                fontWeight: 700,
            },
        },
    } as ThemeOptions);

export function getThemedComponents(theme: Theme): { components: Theme['components'] } {
    return {
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableTouchRipple: true,
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    sizeLarge: {
                        padding: '0.875rem 1rem',
                        ...theme.typography.body1,
                        lineHeight: 21 / 16,
                        fontWeight: 700,
                    },
                    sizeSmall: {
                        padding: theme.spacing(0.5, 1),
                        marginLeft: theme.spacing(-1),
                    },
                    containedPrimary: {
                        backgroundColor: theme.palette.primary[500],
                        color: '#fff',
                    },
                },
                variants: [
                    {
                        props: { variant: 'code' },
                        style: {
                            color: colorData[theme.palette.mode].button.code.color,
                            border: '1px solid',
                            borderColor: colorData[theme.palette.mode].button.code.borderColor,
                            backgroundColor:
                                colorData[theme.palette.mode].button.code.backgroundColor,
                            fontWeight: 400,
                            fontSize: defaultTheme.typography.pxToRem(13),
                            lineHeight: 21 / 14,
                            letterSpacing: 0,
                            WebkitFontSmoothing: 'subpixel-antialiased',
                            '&:hover, &.Mui-focusVisible': {
                                borderColor: theme.palette.primary.main,
                                backgroundColor:
                                    colorData[theme.palette.mode].button.code.webkit
                                        .backgroundColor,
                                '& .MuiButton-endIcon': {
                                    color: colorData[theme.palette.mode].button.code.webkit.color,
                                },
                            },
                            '& .MuiButton-startIcon': {
                                color: colorData[theme.palette.mode].button.code.icon,
                            },
                            '& .MuiButton-endIcon': {
                                display: 'inline-block',
                                position: 'absolute',
                                right: 0,
                                marginRight: 10,
                                color: colorData[theme.palette.mode].button.code.icon,
                            },
                        },
                    },
                    {
                        props: { variant: 'link' },
                        style: {
                            fontSize: theme.typography.pxToRem(14),
                            fontWeight: 700,
                            color: colorData[theme.palette.mode].button.link.color,
                            mb: 1,
                            '& svg': {
                                ml: -0.5,
                            },
                        },
                    },
                    {
                        props: { variant: 'normal' },
                        style: {
                            fontSize: theme.typography.pxToRem(14),
                            fontWeight: 700,
                            color: colorData[theme.palette.mode].button.normal.color,
                            backgroundColor: colorData[theme.palette.mode].button.normal.backgroundColor,
                            borderRadius: 0,
                            mb: 1,
                            '& svg': {
                                ml: -0.5,
                            },
                        },
                    },
                    {
                        props: { variant: 'dashed' },
                        style: {
                            border: colorData[theme.palette.mode].button.dashed.border,
                        },
                    },
                ],
            },
            MuiIconButton: {
                variants: [
                    {
                        props: { color: 'primary' },
                        style: {
                            height: 34,
                            width: 34,
                            border: `1px solid ${
                                colorData[theme.palette.mode].button.icon.borderColor
                            }`,
                            borderRadius: theme.shape.borderRadius,
                            color: colorData[theme.palette.mode].button.icon.color,
                            '&:hover': {
                                borderColor:
                                    colorData[theme.palette.mode].button.icon.hover.borderColor,
                                background:
                                    colorData[theme.palette.mode].button.icon.hover.backgroundColor,
                            },
                        },
                    },
                ],
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        mt: 0.5,
                        minWidth: 160,
                        elevation: 0,
                        color: theme.palette.text.secondary,
                        backgroundImage: 'none',
                        backgroundColor: colorData[theme.palette.mode].menu.backgroundColor,
                        border: `1px solid ${colorData[theme.palette.mode].menu.borderColor}`,
                        '& .MuiMenuItem-root': {
                            fontSize: theme.typography.pxToRem(14),
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: colorData[theme.palette.mode].menu.item.hover,
                            },
                            '&:focus': {
                                backgroundColor: colorData[theme.palette.mode].menu.item.focus,
                            },
                            '&.Mui-selected': {
                                fontWeight: 500,
                                color: colorData[theme.palette.mode].menu.item.selectedColor,
                                backgroundColor:
                                    colorData[theme.palette.mode].menu.item.selectedBackground,
                            },
                        },
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        boxShadow: `0px 4px 20px ${
                            colorData[theme.palette.mode].popover.boxShadow
                        }`,
                    },
                },
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        [theme.breakpoints.up('md')]: {
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                        },
                        backgroundColor: colorData[theme.palette.mode].container.backgroundColor,
                        color: colorData[theme.palette.mode].container.color,
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: colorData[theme.palette.mode].divider,
                    },
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: 'none',
                },
                styleOverrides: {
                    root: {
                        color: colorData[theme.palette.mode].link.color,
                        fontWeight: 700,
                        display: 'inline-flex',
                        alignItems: 'center',
                        '&:hover': {
                            color: colorData[theme.palette.mode].link.hoverColor,
                        },
                        '&.MuiTypography-body1 > svg': {
                            marginTop: 2,
                        },
                        '& svg:last-child': {
                            marginLeft: 2,
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: ({ ownerState: { color, variant } }) => ({
                        fontWeight: 500,
                        ...(variant === 'outlined' &&
                            color === 'default' && {
                                color: colorData[theme.palette.mode].chip.outlined.color,
                                backgroundColor:
                                    colorData[theme.palette.mode].chip.outlined.backgroundColor,
                                borderColor:
                                    colorData[theme.palette.mode].chip.outlined.borderColor,
                            }),
                        ...(variant === 'filled' &&
                            color === 'default' && {
                                border: '1px solid transparent',
                                color: colorData[theme.palette.mode].chip.filled.color,
                                backgroundColor:
                                    colorData[theme.palette.mode].chip.filled.backgroundColor,
                                '&:hover': {
                                    backgroundColor:
                                        colorData[theme.palette.mode].chip.filled.hover
                                            .backgroundColor,
                                },
                            }),
                        // @ts-ignore
                        ...(variant === 'light' && {
                            ...(color === 'default' && {
                                color: colorData[theme.palette.mode].chip.light.standard.color,
                                backgroundColor:
                                    colorData[theme.palette.mode].chip.light.standard
                                        .backgroundColor,
                            }),
                            ...(color === 'warning' && {
                                color: colorData[theme.palette.mode].chip.light.warning.color,
                                backgroundColor:
                                    colorData[theme.palette.mode].chip.light.warning
                                        .backgroundColor,
                            }),
                            ...(color === 'success' && {
                                color: colorData[theme.palette.mode].chip.light.success.color,
                                backgroundColor:
                                    colorData[theme.palette.mode].chip.light.success
                                        .backgroundColor,
                            }),
                        }),
                    }),
                    deleteIcon: {
                        color: colorData[theme.palette.mode].chip.deleteIcon.color,
                        '&:hover': {
                            color: colorData[theme.palette.mode].chip.deleteIcon.hoverColor,
                        },
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        padding: 0,
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        textTransform: 'none',
                        fontWeight: 500,
                        fontSize: theme.typography.pxToRem(14),
                        color: colorData[theme.palette.mode].listItemButton.color,
                        borderRadius: 0,
                        '&:hover': {
                            backgroundColor:
                                colorData[theme.palette.mode].listItemButton.hover.backgroundColor,
                        },
                        '&.Mui-selected': {
                            color: colorData[theme.palette.mode].listItemButton.selected.color,
                            borderRadius: 10,
                            border: '1px solid',
                            borderColor: `${
                                colorData[theme.palette.mode].listItemButton.selected.borderColor
                            } !important`,
                            backgroundColor:
                                colorData[theme.palette.mode].listItemButton.selected
                                    .backgroundColor,
                            '&:hover': {
                                backgroundColor:
                                    colorData[theme.palette.mode].listItemButton.selected.hover
                                        .backgroundColor,
                            },
                        },
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    IconComponent: ArrowDropDownRounded,
                },
                styleOverrides: {
                    iconFilled: {
                        top: 'calc(50% - .25em)',
                    },
                },
            },
            MuiTab: {
                defaultProps: {
                    disableTouchRipple: true,
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundImage: colorData[theme.palette.mode].appBar.backgroundImage,
                        backgroundColor: colorData[theme.palette.mode].appBar.backgroundColor,
                        '&[href]': {
                            textDecorationLine: colorData[theme.palette.mode].appBar.href,
                        },
                        color: colorData[theme.palette.mode].appBar.color,
                        borderColor: colorData[theme.palette.mode].appBar.borderColor,
                        boxShadow: `0px 1px 5px ${colorData[theme.palette.mode].appBar.boxShadow}`,
                        'a&, button&': {
                            '&:hover': {
                                boxShadow: `0px 4px 20px ${
                                    colorData[theme.palette.mode].appBar.linkButtonHoverShadow
                                }`,
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: colorData[theme.palette.mode].paper.root.backgroundImage,
                        backgroundColor: colorData[theme.palette.mode].paper.root.backgroundColor,
                        '&[href]': {
                            textDecorationLine: colorData[theme.palette.mode].paper.root.href,
                        },
                    },
                    outlined: {
                        display: colorData[theme.palette.mode].paper.outlined.display,
                        borderColor: colorData[theme.palette.mode].paper.outlined.borderColor,
                        ...(colorData[theme.palette.mode].paper.outlined.backgroundColor && {
                            backgroundColor:
                                colorData[theme.palette.mode].paper.outlined.backgroundColor,
                        }),
                        'a&, button&': {
                            '&:hover': {
                                boxShadow: `0px 4px 20px ${
                                    colorData[theme.palette.mode].paper.outlined
                                        .linkButtonHoverShadow
                                }`,
                            },
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(1, 2),
                        borderColor: theme.palette.divider,
                    },
                    head: {
                        color: theme.palette.text.primary,
                        fontWeight: 700,
                    },
                    body: {
                        color: theme.palette.text.secondary,
                    },
                },
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    root: {
                        backgroundColor:
                            colorData[theme.palette.mode].toggleButtonGroup.backgroundColor,
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        fontWeight: 500,
                        color: colorData[theme.palette.mode].toggleButton.color,
                        borderColor: colorData[theme.palette.mode].toggleButton.borderColor,
                        '&.Mui-selected': {
                            color: colorData[theme.palette.mode].toggleButton.selected.color,
                            borderColor: `${
                                colorData[theme.palette.mode].toggleButton.selected.borderColor
                            } !important`,
                            backgroundColor:
                                colorData[theme.palette.mode].toggleButton.selected.backgroundColor,
                            '&:hover': {
                                backgroundColor:
                                    colorData[theme.palette.mode].toggleButton.selected.hover
                                        .backgroundColor,
                            },
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        padding: '5px 9px',
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        width: 32,
                        height: 20,
                        padding: 0,
                        '& .MuiSwitch-switchBase': {
                            '&.Mui-checked': {
                                transform: 'translateX(11px)',
                                color: '#fff',
                            },
                        },
                    },
                    switchBase: {
                        height: 20,
                        width: 20,
                        padding: 0,
                        color: '#fff',
                        '&.Mui-checked + .MuiSwitch-track': {
                            opacity: 1,
                        },
                    },
                    track: {
                        opacity: 1,
                        borderRadius: 32,
                        backgroundColor: colorData[theme.palette.mode].switch.track.backgroundColor,
                    },
                    thumb: {
                        flexShrink: 0,
                        width: '14px',
                        height: '14px',
                    },
                },
            },
            MuiPaginationItem: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        fontWeight: 700,
                        color: colorData[theme.palette.mode].paginationItem.color,
                        borderColor: colorData[theme.palette.mode].paginationItem.borderColor,
                        '&.Mui-selected': {
                            color: colorData[theme.palette.mode].paginationItem.selected.color,
                            borderColor: `${
                                colorData[theme.palette.mode].paginationItem.selected.borderColor
                            } !important`,
                            backgroundColor:
                                colorData[theme.palette.mode].paginationItem.selected
                                    .backgroundColor,
                            '&:hover': {
                                backgroundColor:
                                    colorData[theme.palette.mode].paginationItem.selected.hover
                                        .backgroundColor,
                            },
                        },
                    },
                },
            },
            MuiCssBaseline: {
                defaultProps: {
                    enableColorScheme: true,
                },
            },
        },
    };
}
