import { Button, Container, Typography } from '@mui/material';
import React from 'react';
import { deleteReq, get, post } from '../../global';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { AccountCircle, PedalBike } from '@mui/icons-material';

export class BookingViewComponent extends React.Component {
    columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            valueGetter: params => `${params.row.firstName} ${params.row.lastName}`,
        },
        {
            field: 'book',
            headerName: 'Buchen',
            width: 100,
            renderCell: params => (
                <Button
                    variant="normal"
                    sx={{
                        my: 2,
                        display: 'block',
                        fontSize: 13,
                        alignSelf: 'center',
                    }}
                    onClick={() => this.placeReservation(params.row.email)}
                >
                    Buchen
                </Button>
            ),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            pool: [],
            orderPoints: 0,
            bookingPoints: 0,
        };
        this.fetchPointInfo = this.fetchPointInfo.bind(this);
        this.fetchReferees = this.fetchReferees.bind(this);
        this.placeReservation = this.placeReservation.bind(this);
        this.revokeReservation = this.revokeReservation.bind(this);
    }

    componentDidMount() {
        this.fetchPointInfo();
        this.fetchReferees();
    }

    render() {
        return (
            <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Stack
                    direction="column"
                    justifyContent="left"
                    height="10vh"
                    alignItems="left"
                    spacing={2}
                >
                    <Stack
                        direction="row"
                        justifyContent="left"
                        height="10vh"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography variant="h1" color={'black'}>
                            Referenten buchen
                        </Typography>
                        <Button
                            variant="normal"
                            sx={{
                                my: 2,
                                display: 'block',
                                fontSize: 13,
                                alignSelf: 'center',
                            }}
                            onClick={() => this.props.navigate('/geraetepool')}
                        >
                            Stattdessen Geräte bestellen
                        </Button>
                        <PedalBike sx={{ ml: 3, mr: 1, fontSize: '60px' }} color='warning'></PedalBike>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            height="10vh"
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography variant="p" color={'black'}>
                                Verfügbar:
                            </Typography>
                            <Typography variant="h2" color={'black'}>
                                {this.state.orderPoints} / 15000
                            </Typography>
                        </Stack>
                        <AccountCircle sx={{ ml: 3, mr: 1, fontSize: '60px' }} color='warning'></AccountCircle>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            height="10vh"
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography variant="p" color={'black'}>
                                Verfügbar:
                            </Typography>
                            <Typography variant="h2" color={'black'}>
                                {this.state.bookingPoints} / 5
                            </Typography>
                        </Stack>
                        
                        <Stack>
                        </Stack>
                    </Stack>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            getRowId={row => row.uuid}
                            rows={this.state.pool}
                            columns={this.columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 30, 50, 100]}
                            rowSelection={false}
                        />
                    </div>
                </Stack>
            </Container>
        );
    }

    fetchPointInfo() {
        get('api/v1/user/me', (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    orderPoints: data.availablePoints,
                    bookingPoints: data.availableReservations,
                });
            } else {
                this.props.navigate('/login');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }

    fetchReferees() {
        post('api/v1/teacher/reservations/available?page=0&size=999999', {}, (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    pool: data.content,
                });
            } else if (status === 401) {
                this.props.navigate('/login');
                this.props.changeBar('Melde dich erneut an!', 'error');
            } else {
                this.props.navigate('/');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }

    placeReservation(refereeEmail) {
        post('api/v1/teacher/reservations', {
            referee: refereeEmail
        }, (data, status) => {
            if (status === 200 && data !== undefined) {
                this.props.changeBar('Referent erfolgreich gebucht!', 'success');
                this.fetchPointInfo();
                this.fetchReferees();
            } else if (status === 400) {
                this.props.changeBar('Du hast keine weiteren Reservierungen mehr!', 'error');
            } else if (status === 401) {
                this.props.navigate('/login');
                this.props.changeBar('Melde dich erneut an!', 'error');
            } else {
                this.props.navigate('/');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }

    revokeReservation(email) {
        deleteReq(`api/v1/teacher/reservations/${email}`, (data, status) => {
            if (status === 200 && data !== undefined) {
                this.props.changeBar('Referent erfolgreich gebucht!', 'success');
                this.fetchReferees();
            } else if (status === 401) {
                this.props.navigate('/login');
                this.props.changeBar('Melde dich erneut an!', 'error');
            } else {
                this.props.navigate('/');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }
}
