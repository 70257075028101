import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from '../src/locales/en.json';
import german from '../src/locales/de.json';

export function initTranslations() {
    i18n.use(initReactI18next).init({
        resources: {
            en: {
                translation: english,
            },
            de: {
                translation: german,
            },
        },
        lng: 'de',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });
}
