import { Container, Typography, Box } from '@mui/material';
import React from 'react';
import './Goals.css';

export class GoalsComponent extends React.Component {
    render() {
        return (
            <Container sx={{ my: 3, p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Typography variant="h1" color={'black'} textAlign="center">
                    Unsere Ziele
                </Typography>

                <Container>
                    <Box>
                        <Box sx={{ marginTop: 5 }}>
                            <Typography
                                sx={{ marginBottom: 2 }}
                                variant="h3"
                                color={'black'}
                                textAlign="left"
                            >
                                Zweck der Vereinigung
                            </Typography>
                            <Typography variant="body1" color={'black'} textAlign="left">
                                Gesundheitsförderung von Schüler/innen im Sinne der WHO (World
                                Health Organisation), das ist die Förderung und Erhaltung der
                                körperlichen, geistigen, seelischen und sozialen Gesundheit im
                                ganzheitlichen Sinn über die Bewegung
                            </Typography>
                        </Box>

                        <Box sx={{ marginTop: 10 }}>
                            <Typography
                                sx={{ marginBottom: 2 }}
                                variant="h3"
                                color={'black'}
                                textAlign="left"
                            >
                                Vereinsziele
                            </Typography>
                            <Typography variant="body1" color={'black'} textAlign="left">
                                <ul sx={{ listStyleType: 'disc', m: 1250, p: 0 }}>
                                    <li style={{ marginTop: '0px' }}>
                                        die Mithilfe und Unterstützung bei der Installierung und
                                        Durchführung von Schwerpunktklassen an Schulen zum Thema
                                        „Bewegtes Lernen – Gesundheitsförderung“
                                    </li>
                                    <li>
                                        die Information und/oder Aufklärung von Schüler/innen,
                                        Pädagog/innen und Eltern über die, die Gesundheit
                                        beeinflussenden, seelischen, geistigen, körperlichen und
                                        sozialen Faktoren bzw. über vermeidbare Krankheiten und
                                        Haltungsschäden;
                                    </li>
                                    <li>
                                        die Entwicklung von Konzepten für die Aus- und Fortbildung
                                        von Pädagog/innen zum Thema „Bewegtes Lernen –
                                        Gesundheitsförderung“; die Organisation und Durchführung des
                                        Diplomlehrganges Bewegtes Lernen – Gesundheitsförderung;
                                    </li>
                                    <li>
                                        die Vermittlung von Expert/innen an Schulen für
                                        Projektklassen, Schulveranstaltungen und Elternabende;
                                    </li>
                                    <li>
                                        die Verbesserung der Lernvoraussetzungen und Lernbedingungen
                                        von Schüler/innen;
                                    </li>
                                    <li>
                                        die Kooperation mit gesundheitsfördernden Institutionen und
                                        Schulen;
                                    </li>
                                    <li>
                                        Abhaltung von Gesundheitstagen, Elternabenden,
                                        Projektveranstaltungen und Workshops;
                                    </li>
                                    <li>
                                        wissenschaftliche Studien zur Implementierung des Bewegten
                                        Lernens und der Gesundheitsförderung in das
                                        Schulentwicklungsprogramm
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Container>
        );
    }
}
