import { Component } from 'react';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

class BasicCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Link
      to={this.props.URI}
      style={{ textDecoration: 'none', alignSelf: 'center' }}
      onClick={this.closeAppBar}
      > 
        <Box alt={this.props.alt} style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${this.props.srcImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          overflow: 'hidden',
          borderRadius: '10px',
        }}>
          <Box style={{
            textAlign: 'center',
            alignSelf: 'end',
            marginTop: '85%',
            width: '80%',
            height: 'fit-content',
            marginLeft: '10%',
            marginRight: '10%',
            borderRadius: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }}>
            <div style={{color: 'black', fontSize: '20px'}}>{this.props.Header}</div>
          </Box>
        </Box>
      </Link>
    );
  }
}

export default BasicCard;