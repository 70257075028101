export let base = 'https://api.bwl.30062022.xyz';
//export let base = 'http://localhost:7780';
export const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function get(url, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('GET', `${base}/${url}`, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(null);
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = JSON.parse(req.responseText);
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function getRaw(url, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('GET', `${base}/${url}`, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(null);
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = req.responseText;
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function post(url, data, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('POST', `${base}/${url}`, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(JSON.stringify(data));
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = JSON.parse(req.responseText);
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function postType(url, type, data, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('POST', `${base}/${url}`, true);
    req.setRequestHeader('Content-Type', type);
    req.send(data);
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = JSON.parse(req.responseText);
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function postImage(url, data, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('POST', `${base}/${url}`, true);
    req.send(data);
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = JSON.parse(req.responseText);
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function put(url, data, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('PUT', `${base}/${url}`, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(JSON.stringify(data));
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = JSON.parse(req.responseText);
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function patch(url, data, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('PATCH', `${base}/${url}`, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(JSON.stringify(data));
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = JSON.parse(req.responseText);
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function deleteReq(url, callback) {
    let req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open('DELETE', `${base}/${url}`, true);
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (req.status === 200) {
            let data = undefined;
            try {
                data = JSON.parse(req.responseText);
            } catch {}
            callback(data, req.status);
        } else {
            callback([], req.status);
        }
    };
}

export function unsubscribe(changeBar) {
    navigator.serviceWorker.ready
        .then(function (serviceWorkerRegistration) {
            serviceWorkerRegistration.pushManager
                .getSubscription()
                .then(function (subscription) {
                    subscription.unsubscribe().then(
                        val => changeBar('Benachrichtigungen ausgeschaltet!', 'success'),
                        err => changeBar('Fehler beim Ausschalten!', 'error'),
                    );
                })
                .catch(function (err) {
                    console.warn('Error during getSubscription()', err);
                    changeBar('Fehler beim Ausschalten!', 'error');
                });
        })
        .catch(err => changeBar(err, 'error'));
}

export function requestSubscription(changeBar) {
    if (
        !('showNotification' in ServiceWorkerRegistration.prototype) &&
        !('PushManager' in window)
    ) {
        console.warn("Notifications aren't supported.");
        changeBar('Benachrichtigungen nicht unterstützt!', 'error');
        return;
    }

    if ('Notification' in window && Notification.permission === 'denied') {
        console.warn('The user has blocked notifications.');
        changeBar('Benachrichtigungen abgelehnt!', 'error');
        return;
    }

    changeBar(
        navigator.serviceWorker && window.PushManager && window.Notification ? 'Yes' : 'No',
        'info',
    );

    navigator.serviceWorker.ready
        .then(function (serviceWorkerRegistration) {
            serviceWorkerRegistration.pushManager
                .getSubscription()
                .then(function (subscription) {
                    if (!subscription) {
                        subscribe(changeBar);
                        return;
                    }
                    sendSubscriptionToServer(subscription, changeBar);
                })
                .catch(function (err) {
                    console.warn('Error during getSubscription()', err);
                    changeBar('Benachrichtigungen konnten nicht aktiviert werden!', 'error');
                });
        })
        .catch(err => changeBar(err, 'error'));
}

export function subscribe(changeBar) {
    navigator.serviceWorker.ready
        .then(function (serviceWorkerRegistration) {
            serviceWorkerRegistration.pushManager
                .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(
                        'BEkD1ao0W9Isnn_BRze5GP5CUHoOB94-hOXqWX4CgxFBW6j2T1_Vz6JMwsDwRLdLic7haot0c6Ot5Ybybq2j_OE',
                    ),
                })
                .then(function (subscription) {
                    return sendSubscriptionToServer(subscription, changeBar);
                })
                .catch(function (e) {
                    if ('Notification' in window && Notification.permission === 'denied') {
                        console.warn('Permission for Notifications was denied');
                    } else {
                        console.error('Unable to subscribe to push.', e);
                    }
                    changeBar('Benachrichtigungen konnten nicht aktiviert werden!', 'error');
                });
        })
        .catch(err => changeBar(err, 'error'));
}

function sendSubscriptionToServer(subscription, changeBar) {
    console.log('Subscription:');
    console.log(subscription);
    var key = subscription.getKey ? subscription.getKey('p256dh') : '';
    var auth = subscription.getKey ? subscription.getKey('auth') : '';
    const endpoint = subscription.endpoint;
    const pkey = key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '';
    const pauth = auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : '';

    return post(
        `api/v1/notifications/subscribe`,
        `endpoint=${endpoint}&key=${pkey}&auth=${pauth}`,
        (data, status) => {
            if (status === 200) {
                changeBar('Benachrichtigungen aktiviert!', 'success');
            } else {
                changeBar('Benachrichtigungen konnten nicht aktiviert werden!', 'error');
            }
        },
    );
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
