import {
    Avatar,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import React from "react";
import { emailRegex, get, post, postType } from "../../global";

export class LoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            codeStep: false,
            codeSkipped: false,
            rememberMeButton: false,
            rmb: false,
            buttonDisabled: false,
            shouldRerender: true,
        };
        this.username = this.username.bind(this);
        this.password = this.password.bind(this);
        this.login = this.login.bind(this);
        this.rmb = this.rmb.bind(this);
        this.login_email = this.login_email.bind(this);
        this.login_code = this.login_code.bind(this);
        this.checkLogged = this.checkLogged.bind(this);
        this.skipCode = this.skipCode.bind(this);
    }

    componentDidMount() {
        this.checkLogged();
    }

    render() {
        return (
            <Paper
                spacing={0}
                direction="column"
                style={{ height: "100%", width: "100%" }}
                sx={{ p: 3, borderRadius: 0 }}
            >
                <Grid align="center" sx={{ mt: { xs: 0, md: 3 } }}>
                    <Avatar>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography variant="h4">{this.state.title}</Typography>
                </Grid>
                {
                    !this.state.codeStep ? (
                        <TextField
                            label={'E-Mail'}
                            placeholder={'E-Mail'}
                            type="email"
                            name="emailAddress"
                            value={this.state.username}
                            onChange={this.username}
                            error={!this.state.username.match(emailRegex)}
                            sx={{
                                display: "block",
                                mx: "auto",
                                mt: 2,
                                mb: 0.5,
                                width: { xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw" },
                            }}
                            fullWidth
                            required
                        />
                    ) : (
                        <TextField
                            label={this.state.codeSkipped ? "Passwort" : "Code"}
                            placeholder={this.state.codeSkipped ? "Passwort" : "Code"}
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.password}
                            error={this.state.codeSkipped ? this.state.password.length < 8 : this.state.password !== 6}
                            sx={{
                                display: "block",
                                mx: "auto",
                                mt: 2,
                                mb: 0.5,
                                width: { xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw" },
                            }}
                            fullWidth
                            required
                        />
                    )
                }
                {
                    this.state.rememberMeButton &&
                    (<FormControlLabel
                        control={
                            <Checkbox
                                name="remember-me"
                                color="primary"
                                value={this.state.rmb}
                                onChange={this.rmb}
                            />
                        }
                        label="Angemeldet bleiben"
                        sx={{
                            display: "block",
                            mx: "auto",
                            width: { xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw" },
                        }}
                    />)
                }
                <Button
                    type="submit"
                    onClick={this.login}
                    color="primary"
                    variant="contained"
                    disabled={this.state.buttonDisabled}
                    sx={{
                        display: "block",
                        mx: "auto",
                        mt: 2,
                        mb: 2,
                        width: { xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw" },
                    }}
                >
                    {
                        !this.state.codeStep ? "Code anfragen" : "Anmelden"
                    }
                </Button>
                {
                    !this.state.codeStep && (
                        <Button
                            type="submit"
                            onClick={this.skipCode}
                            color="primary"
                            variant="code"
                            disabled={this.state.buttonDisabled}
                            sx={{
                                display: "block",
                                mx: "auto",
                                mt: 2,
                                mb: 2,
                                width: { xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw" },
                            }}
                        >
                            Stattdessen mit Passwort anmelden
                        </Button>
                    )
                }
            </Paper>
        );
    }
    username(e) {
        this.setState({ username: e.target.value });
    }
    password(e) {
        this.setState({ password: e.target.value });
    }
    skipCode(e) {
        const username = this.state.username;
        if (username.match(emailRegex)) {
            this.setState({
                username: username,
                rememberMeButton: true,
                codeStep: true,
                codeSkipped: true
            });
        } else {
            this.props.changeBar("Ungültiges E-Mail", "error");
        }
    }
    rmb(e) {
        this.setState({ rmb: e.target.checked });
    }
    login() {
        if (!this.state.codeStep) {
            this.login_email();
        }
        else {
            this.login_code();
        }
        this.setState({ buttonDisabled: true });
    }
    login_email() {
        post(
            "api/v1/user/request/otp",
            {
                username: this.state.username
            },
            (data, status) => {
                if (status === 200) {
                    this.props.changeBar("Code abgesendet!", "success");
                    this.setState({
                        rememberMeButton: true,
                        codeStep: true
                    })
                }
                this.setState({ buttonDisabled: false });
            }
        );
    }
    login_code() {
        let email = this.state.username;
        let code = this.state.password;

        postType(
            "api/v1/login",
            "application/x-www-form-urlencoded",
            `username=${email}&password=${code}${this.state.rmb ? "&remember-me=on" : ""}`,
            (data, status) => {
                if (status === 200) {
                    this.props.changeBar("Login erfolgreich!", "success");
                    this.setState({ buttonDisabled: false });
                    this.checkLogged();
                } else if (status === 400) {
                    this.props.changeBar("Falscher Code oder falsches Passwort", "error");
                    this.setState({ buttonDisabled: false });
                }
            }
        );
    }
    checkLogged() {
        get("api/v1/user/me", (data, status) => {
            if (status === 200) {
                this.props.setPerm(true, data.role);
                this.props.navigate("/");
            }
        });
    }
}
