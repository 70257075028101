import { Button, Container, Typography } from '@mui/material';
import React from 'react';
import { get, post } from '../../global';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { Check, Close } from '@mui/icons-material';

export class ReservationViewComponent extends React.Component {
    columns = [
        {
            field: 'teacherName',
            headerName: 'Name',
            width: 300,
        },
        {
            field: 'teacherEmail',
            headerName: 'Email',
            width: 300,
            renderCell: params => (
                <a href={'mailto:' + params.row.teacherEmail + '?subject=Referent für dein Projekt'} 
                   onClick={() => this.markReservationContacted(params.row.teacherEmail)}>
                    <Button
                    variant="link"
                    sx={{
                        my: 2,
                        display: 'block',
                        fontSize: 13,
                        alignSelf: 'center',
                        color: 'black'
                    }}
                >
                    {params.row.teacherEmail}
                </Button>
                </a>
            ),
        },
        {
            field: 'teacherPhoneNr',
            headerName: 'Telefon Nr.',
            width: 300,
            renderCell: params => (
                <a href={'tel:' + params.row.teacherPhoneNr?.replaceAll(/\s\s+/g, '')} 
                   onClick={() => this.markReservationContacted(params.row.teacherEmail)}>
                    <Button
                    variant="link"
                    sx={{
                        my: 2,
                        display: 'block',
                        fontSize: 13,
                        alignSelf: 'center',
                        color: 'black'
                    }}
                >
                    {params.row.teacherPhoneNr}
                </Button>
                </a>
            ),
        },
        {
            field: 'contacted',
            headerName: 'Bereits kontaktiert?',
            width: 200,
            renderCell: params => params.row.contacted ? (
                <Check color='success' />
            ) : (
                <Close color='error'/>
            ),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            reservations: []
        };
        this.fetchReservations = this.fetchReservations.bind(this);
        this.markReservationContacted = this.markReservationContacted.bind(this);
    }

    componentDidMount() {
        this.fetchReservations();
    }

    render() {
        return (
            <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Stack
                    direction="column"
                    justifyContent="left"
                    height="10vh"
                    alignItems="left"
                    spacing={2}
                >
                    <Stack
                        direction="row"
                        justifyContent="left"
                        height="10vh"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography variant="h1" color={'black'}>
                            Folgende Lehrkräfte suchen nach Ihnen:
                        </Typography>
                    </Stack>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            getRowId={row => row.uuid}
                            rows={this.state.reservations}
                            columns={this.columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 30, 50, 100]}
                            rowSelection={false}
                        />
                    </div>
                </Stack>
            </Container>
        );
    }

    fetchReservations() {
        get('api/v1/referee/reservations', (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    reservations: data,
                });
            } else if (status === 401) {
                this.props.navigate('/login');
                this.props.changeBar('Melde dich erneut an!', 'error');
            } else {
                this.props.navigate('/');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }

    markReservationContacted(teacherEmail) {
        post('api/v1/referee/reservations', {
            teacher: teacherEmail
        }, (data, status) => {
            if (status === 200 && data !== undefined) {
            }
        });
    }
}
