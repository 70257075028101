import { Container, Grid, Pagination, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Parser } from "html-to-react";
import "./NewsView.css";
import React from "react";
import { post } from "../../global";

export class NewsViewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shownPage: 1,
            totalPages: 1,
            news: [],
        }
        this.switchPage = this.switchPage.bind(this);
        this.scrollToTargetAdjusted = this.scrollToTargetAdjusted.bind(this);
        this.scrollBackToTop = this.scrollBackToTop.bind(this);
    }

    componentDidMount() {
        this.switchPage(1);
    }

    switchPage(page) {
        post(`api/v1/mail/news/search?size=1&page=${page - 1}`, {
            searchTerm: ''
        }, (data, status) => {
            if (status === 200) {
                this.setState({
                    totalPages: data.totalPages,
                    shownPage: page,
                    news: data.content,
                });
            }
        });
    }

    scrollToTargetAdjusted(id) {
        var element = document.getElementById(id);
        var headerOffset = 100;
        var elementPosition = element.offsetTop;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    scrollBackToTop(value) {
        this.scrollToTargetAdjusted("NewsHeader");
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={1} sx={{ backgroundColor: '#E4630D' }}>
                </Grid>

                <Grid item xs={10}>
                    <Box id="News-Box" sx={{ mx: '15%' }}>
                        <Typography variant="h1" id="NewsHeader" sx={{ textAlign: 'center', mt: '2%', color: 'black' }}>
                            News
                        </Typography>

                        <Container>
                            {this.state.news.length === 0 ? (
                                <Paper elevation={3} sx={{ mt: '2%', p: '2%', borderRadius: '10px' }}>
                                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                                        Neuigkeiten ladet...
                                    </Typography>
                                </Paper>
                            ) : (this.state.news.map((news, index) => {
                                return (
                                    <Paper key={index} elevation={3} sx={{ mt: '2%', p: '2%', borderRadius: '10px' }}>
                                        <Typography variant="h4" sx={{ textAlign: 'center' }}>
                                            {news.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                            {Parser().parse(news.content)}
                                        </Typography>
                                    </Paper>
                                )
                            }))}
                        </Container>

                        <Box sx={{ display: 'flex', justifyContent: 'center', my: '3%' }}>
                            <Pagination
                                count={this.state.totalPages}
                                page={this.state.shownPage}
                                onChange={(event, value) => {
                                    this.switchPage(value);
                                    this.scrollBackToTop(value);
                                }}
                                color="primary"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={1} sx={{ backgroundColor: '#E4630D' }}>
                </Grid>
            </Grid>
        )
    }
}
