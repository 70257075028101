import {
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography,
} from '@mui/material';
import React from 'react';
import { base, get, post } from '../../global';
import { Box, Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { AccountCircle, PedalBike } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export class DeviceViewComponent extends React.Component {
    columns = [
        {
            field: 'uuid',
            headerName: 'Bild',
            width: 170,
            renderCell: params => (
                <Button
                    variant="normal"
                    sx={{
                        my: 2,
                        display: 'block',
                        fontSize: 13,
                        alignSelf: 'center',
                    }}
                    onClick={() => this.showImage(params.row.uuid)}
                >
                    Ansehen
                </Button>
            ),
        },
        { field: 'name', headerName: 'Name', width: 170 },
        { field: 'description', headerName: 'Beschreibung', width: 140 },
        { field: 'requiredPoints', headerName: 'Preis (Punkte)', type: 'number', width: 140 },
        {
            field: 'available',
            headerName: 'Verfügbar',
            width: 140,
            valueGetter: params => (params.row.available ? 'Ja' : 'Nein'),
        },
        {
            field: 'order',
            headerName: 'Bestellen',
            width: 170,
            renderCell: params => (
                <Button
                    variant="normal"
                    sx={{
                        my: 2,
                        display: 'block',
                        fontSize: 13,
                        alignSelf: 'center',
                    }}
                    onClick={() => this.showOrder(params.row.name)}
                >
                    Bestellen
                </Button>
            ),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            pool: [],
            openImage: false,
            imageUrl: '',
            openOrder: false,
            orderProject: undefined,
            orderDevice: undefined,
            openReceipt: false,
            receipt: undefined,
            orderPoints: 0,
            bookingPoints: 0,
        };
        this.fetchPointInfo = this.fetchPointInfo.bind(this);
        this.fetchProjects = this.fetchProjects.bind(this);
        this.fetchDevicePool = this.fetchDevicePool.bind(this);
        this.showImage = this.showImage.bind(this);
        this.showOrder = this.showOrder.bind(this);
        this.orderDevice = this.orderDevice.bind(this);
    }

    componentDidMount() {
        this.fetchPointInfo();
        this.fetchProjects();
        this.fetchDevicePool();
    }

    render() {
        return (
            <Container sx={{ p: { sx: 0, sm: 0, md: 2, lg: 3 } }}>
                <Stack
                    direction="column"
                    justifyContent="left"
                    height="10vh"
                    alignItems="left"
                    spacing={2}
                >
                    <Stack
                        direction="row"
                        justifyContent="left"
                        height="10vh"
                        alignItems="center"
                        spacing={3}
                    >
                        <Typography variant="h1" color={'black'}>
                            Gerätepool
                        </Typography>
                        <Button
                            variant="normal"
                            sx={{
                                my: 2,
                                display: 'block',
                                fontSize: 13,
                                alignSelf: 'center',
                            }}
                            onClick={() => this.props.navigate('/referenten')}
                        >
                            Stattdessen Referenten buchen
                        </Button>
                        <PedalBike
                            sx={{ ml: 3, mr: 1, fontSize: '60px' }}
                            color="warning"
                        ></PedalBike>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            height="10vh"
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography variant="p" color={'black'}>
                                Verfügbar:
                            </Typography>
                            <Typography variant="h2" color={'black'}>
                                {this.state.orderPoints} / 15000
                            </Typography>
                        </Stack>
                        <AccountCircle
                            sx={{ ml: 3, mr: 1, fontSize: '60px' }}
                            color="warning"
                        ></AccountCircle>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            height="10vh"
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography variant="p" color={'black'}>
                                Verfügbar:
                            </Typography>
                            <Typography variant="h2" color={'black'}>
                                {this.state.bookingPoints} / 5
                            </Typography>
                        </Stack>
                    </Stack>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            getRowId={row => row.uuid}
                            rows={this.state.pool}
                            columns={this.columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 30, 50, 100]}
                            rowSelection={false}
                        />
                    </div>
                </Stack>
                <Modal
                    open={this.state.openImage}
                    onClose={() => this.setState({ openImage: false })}
                >
                    <Box sx={style}>
                        <img
                            src={this.state.imageUrl}
                            style={{width: '100%'}}
                            alt=""
                            onError={() =>
                                this.setState({
                                    imageUrl: 'img/LandingPage/NewsSection/NewsItemSample.png',
                                })
                            }
                        ></img>
                    </Box>
                </Modal>
                <Modal
                    open={this.state.openOrder}
                    onClose={() => this.setState({ openOrder: false })}
                >
                    <Box sx={style}>
                        <FormControl>
                            <InputLabel>Projekt</InputLabel>
                            <Select
                                value={this.state.orderProject}
                                label="Wähle ein Projekt aus"
                                onChange={event =>
                                    this.setState({ orderProject: event.target.value })
                                }
                            >
                                {this.state.projects.map((val, idx) => {
                                    return <MenuItem key={idx} value={val}>{val}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <Button
                            variant="normal"
                            sx={{
                                my: 2,
                                display: 'block',
                                fontSize: 13,
                                alignSelf: 'center',
                            }}
                            onClick={() => this.orderDevice()}
                        >
                            Jetzt Bestellen
                        </Button>
                    </Box>
                </Modal>
                <Modal
                    open={this.state.openReceipt}
                    onClose={() => this.setState({ openReceipt: false })}
                >
                    <Box sx={style}>
                        <Typography variant="h1" color={'black'}>
                            Bestellung
                        </Typography>
                        <br/>
                        <Typography variant="p" color={'black'}>
                            ProjektNr: {this.state.receipt?.project}
                        </Typography>
                        <br/>
                        <Typography variant="p" color={'black'}>
                            Gerät: {this.state.receipt?.deviceName}
                        </Typography>
                        <br/>
                        <Typography variant="p" color={'black'}>
                            GerätID: {this.state.receipt?.device}
                        </Typography>
                        <br/>
                        <Typography variant="p" color={'black'}>
                            Bestellungsdatum: {this.state.receipt?.receiptDate}
                        </Typography>
                        <br/>
                        <Typography variant="p" color={'black'}>
                            Verbrauchte Punkte: {this.state.receipt?.usedPoints}
                        </Typography>
                    </Box>
                </Modal>
            </Container>
        );
    }

    fetchPointInfo() {
        get('api/v1/user/me', (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    orderPoints: data.availablePoints,
                    bookingPoints: data.availableReservations,
                });
            } else {
                this.props.navigate('/login');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }

    fetchProjects() {
        get('api/v1/teacher/projects/', (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    projects: data.map(project => project.projectNr),
                });
            } else if (status === 401) {
                this.props.navigate('/login');
                this.props.changeBar('Melde dich erneut an!', 'error');
            } else {
                this.props.navigate('/');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }

    fetchDevicePool() {
        get('api/v1/teacher/order/', (data, status) => {
            if (status === 200 && data !== undefined) {
                this.setState({
                    pool: data,
                });
            } else if (status === 401) {
                this.props.navigate('/login');
                this.props.changeBar('Melde dich erneut an!', 'error');
            } else {
                this.props.navigate('/');
                this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
            }
        });
    }

    showImage(uuid) {
        this.setState({
            imageUrl: `${base}/api/v1/teacher/order/${uuid}/image`,
            openImage: true,
        });
    }

    showOrder(device) {
        this.setState({
            orderProject: this.state.projects.length > 0 ? this.state.projects[0] : undefined,
            orderDevice: device,
            openOrder: true,
        });
    }

    orderDevice() {
        if (!this.state.orderProject || !this.state.orderDevice) {
            return;
        }
        post(
            'api/v1/teacher/order/device',
            {
                project: this.state.orderProject,
                deviceName: this.state.orderDevice,
            },
            (data, status) => {
                if (status === 200 && data !== undefined) {
                    this.props.changeBar('Gerät erfolgreich bestellt!', 'success');
                    this.fetchPointInfo();
                    this.fetchDevicePool();
                    this.setState({
                        openOrder: false,
                        orderDevice: undefined,
                        orderProject: undefined,
                        openReceipt: true,
                        receipt: data,
                    });
                } else if (status === 400) {
                    this.props.changeBar('Du hast nicht genug Punkte!', 'error');
                    this.setState({
                        openOrder: false,
                        orderDevice: undefined,
                        orderProjekt: undefined
                    });
                } else if (status === 401) {
                    this.props.navigate('/login');
                    this.props.changeBar('Melde dich erneut an!', 'error');
                } else {
                    this.props.navigate('/');
                    this.props.changeBar('Verbindung fehlgeschlagen!', 'error');
                }
            },
        );
    }
}
