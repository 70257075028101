import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Component } from "react";
import { Parser } from 'html-to-react'

/*
    Parameters:
    * img: string
    * title: string
    * text: string
    * imageKey: 1 or 2
*/

class NewsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            img: "",
            title: this.props.title,
            text: this.props.text,
            textHeight: 0,
        };
    }

    componentDidMount(){
        //render img
        let str = this.props.img + "";
        let imgData = str.split("<img")[1] + "";
        imgData = imgData.split("></p>")[0];

        //build image tag
        let imgKey = "id=\"imageKey" + this.props.imageKey + "\" ";
        let imgStyle = '"width: 100%; height: fit-content; object-fit: contain;"';
        this.setState({ img: ('<img ' + imgKey + imgData + " style=" + imgStyle + "/>") });

        //calculate height for text section
        const imgElement = document.getElementById("imageKey" + this.props.imageKey);
        const imgHeight = imgElement ? imgElement.offsetHeight : 0;
        this.setState({ textHeight: imgHeight });
        
    }

    render() {
        return (
            <Box id="NewsItem" sx={{backgroundColor: 'white'}}>
                <Grid container>
                    <Grid item xs={4} id="NewsItemImage" sx={{ display: 'flex', alignItems: 'center' }}>
                        {Parser().parse(this.state.img)}
                    </Grid>
                    <Grid item xs={0.2}/>
                    <Grid item xs={7.8} sx={{ height: this.state.textHeight + 'px' }}>
                        <Typography variant="h5" sx={{ fontSize: { xs: '80%', sm: '90%', md: '100%' } }} id="NewsItemTitle">
                            {this.state.title}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: { xs: '60%', sm: '70%', md: '80%' }, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }} id="NewsItemText">
                            {this.state.text}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default NewsItem;